import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';
import 'styled-components/macro';
import { motion } from 'framer-motion';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { Heading as UnstyledHeading } from '@components/type';

export const Checkbox = styled.div`
  height: 40px;
  color: ${(props) => props.checked ? colors.N0 : colors.B500};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  line-height: 36px;
  text-align: center;
  background-color: ${(props) => props.checked ? colors.B500: colors.N0};
  border: 2px solid ${(props) => props.checked ? colors.B500 : (props.color || colors.Y200)};
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${mq.gtsm`
    width: calc(33.33% - 4px);
    height: 48px;
    line-height: 44px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  `}
`;

export const Checkboxes = styled.div`
  margin-top: 4px;

  ${mq.gtsm`
    display: flex;
    justify-content: space-between;
  `}
`;

export const Field = styled.div`
  margin-bottom: 16px;

  ${mq.gtsm`
    margin-bottom: 24px;
  `}
`;

export const FormBackground = styled.div`
  padding: 32px ${H_PADDING_MOBILE};
  background-color: ${colors.Y100};

  ${mq.gtsm`
    padding: 64px;
  `}
`;

export const FormError = styled.div`
  color: ${colors.R500};
  font-family: ${props => fonts(props.theme.locale).SANS};
`;

export const FormWrapper = styled.div`
  position: relative;

  ${mq.gtmd`
    width: ${(7 / 12) * 100}%;
  `}

  & > svg:first-of-type {
    fill: ${colors.Y100};
    vertical-align: bottom;
  }

  & > svg:last-of-type {
    fill: ${colors.Y100};
    transform: scale(1, -1) translateY(1px);
    vertical-align: top;
  }
`;

export const Heading = styled(UnstyledHeading)`
  color: ${colors.B500};
  margin-bottom: 8px;

  ${mq.gtsm`
    margin-bottom: 16px;
  `}
`;

export const Info = styled.div`
  padding: 0 ${H_PADDING_MOBILE};

  ${mq.gtmd`
    width: ${(4 / 12) * 100}%;
  `}

  ${mq.gtml`
    padding-left: 0;
  `}
`;

export const FormInner = styled.div`
  position: relative;
  overflow: hidden;

  ${mq.gtmd`
    width: ${(props) => props.hasPerson ? `${(8 / 12) * 100}%` : '100%'};
    margin-left: auto;
    margin-right: 0;
  `}
`;

const inputStyles = css`
  height: 40px;
  width: 100%;
  padding: 0 8px;
  appearance: none;
  font-size: 16px;
  border: 2px solid ${(props) => props.color || colors.Y200};
  border-radius: 8px;
  outline: 0;

  &:focus {
    border-color: ${colors.B200};
  }

  ${(props) => props.error && css`
    border-color: ${colors.R200};
  `}

  ${mq.gtsm`
    height: 48px;
  `}
`;

export const Input = forwardRef(function (props, ref) {
  if (props.type === 'tel') {
    return (
      <MaskedInput
        css={inputStyles}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        guide={false}
        {...props}
      />
    );
  }

  return <input css={inputStyles} {...props} ref={ref} />;
});

export const Inner = styled.div`
  ${mq.gtmd`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
  `}
`;

export const Label = styled.label`
  display: block;
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  line-height: 120%;

  ${(props) => props.error && css`
    color: ${colors.R500};
  `}

  & > *:first-child {
    margin-top: 4px;
  }

  ${mq.gtsm`
    font-size: 18px;
    line-height: 140%;
  `}
`;

export const Person = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${H_PADDING_MOBILE};
  margin-top: 24px;
  margin-bottom: -24px;
  margin-left: -8px;

  ${mq.gtmd`
    position: absolute;
    top: -32px;
    left: -32px;
    flex-direction: column;
    width: ${(4 / 12) * 100}%;
    margin: 0;
    padding: 0;
  `}
`;

export const PersonImageWrapper = styled.div`
  position: relative;
  flex: 1 0 ${(3 / 12) * 100}%;
  margin-right: 16px;

  ${mq.gtmd`
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  `}
`;

export const PersonInfo = styled.div`
  flex: 1 0 auto;

  & h3 {
    margin-bottom: 4px;
  }

  ${mq.gtmd`
    & h3 {
      margin-bottom: 8px;
    }
  `}
`;

export const Success = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 48px;
  text-align: center;

  & > button {
    margin-top: 32px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 8px;
  min-height: 160px;
  max-height: 320px;
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  appearance: none;
  border: 2px solid ${(props) => props.color || colors.Y200};
  border-radius: 8px;
  resize: vertical;
  outline: 0;

  &:focus {
    border-color: ${colors.B200};
  }
`;

export const Wrapper = styled.div`
  ${mq.gtsm`
    padding-top: 32px;
  `}
`;
