import React from 'react';
import ReactPlayer from 'react-player';
import * as styled from './styles';

const VideoEmbed = ({ items, type, name }) => {
  const isAlone = items.length === 1;

  return (
    <styled.Wrapper type={type}>
      {items.map(item => {
        const player = isAlone && type === 'horizontal' ? (
          <styled.RelativePositionWrapper>
            <styled.AbsolutelyPositionedPlayer
              url={item.url}
              width="100%"
              height="100%"
              controls={true}
            />
          </styled.RelativePositionWrapper>
        ) : (
          <ReactPlayer
            url={item.url}
            width="100%"
            height="700px"
            controls={true}
          />
        );

        const widthCol = (item.isWide ? 15 : 10) / 24 * 100;

        return (
          <styled.VideoItem widthCol={widthCol} isAlone={isAlone} type={type}>
            <styled.VideoHeading level={400} type={type}>{item.name}</styled.VideoHeading>
            {player}
            <styled.VideoCaption type={type}>{item.caption}</styled.VideoCaption>
          </styled.VideoItem>
        );
      })}
    </styled.Wrapper>
  );
};

export default VideoEmbed;
