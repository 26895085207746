import { Link as UnstyledLink } from 'gatsby-plugin-react-intl';
import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

export const ExternalLink = styled.a`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  text-decoration: underline;
`;

export const ImageWrapper = styled.div`
  flex: 1 0 auto;
  position: relative;
  width: 80px;
  height: 80px;
  max-width: 80px;
  margin-right: 16px;
  margin-top: 6px;
`;

export const Link = styled(UnstyledLink)`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  text-decoration: underline;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  padding: 16px 0;
  border-top: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        text: colors.B500,
      };
  }
}
