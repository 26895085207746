import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';

export const Actions = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 4px 0;
  color: ${colors.G500};
  font-family: ${props => fonts(props.theme.locale).HANDWRITTEN};
  font-size: 32px;
  line-height: 100%;
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 3px solid ${colors.G500};
  outline: 0;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  width: 100%;

  ${mq.gtsm`
    margin: 0 16px;
    width: ${4 / 12 * 100}%;
  `}
`;

export const Form = styled.form`
  ${mq.gtsm`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
