import styled from 'styled-components';
import { fontStyles, Caption as UnstyledCaption, Heading as UnstyledHeading } from '@components/type';

import { mq } from '@utils/responsive';
import ReactPlayer from 'react-player';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-evenly;
  flex-direction: column;
  vertical-align: top;

  ${mq.gtsm`
    width: ${(props) => props.type === 'vertical' ? '100%' : '100%'};
    flex-direction: row;
    justify-content: space-evenly;
  `}
`;

export const VideoItem = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  ${mq.gtsm`
    padding: 0;
    margin: ${(props) => props.type === 'vertical' ? '0' : '0 50px'};
    width: ${(props) => props.isAlone ? `${props.widthCol}%` : '100%'};
  `}
`;

export const VideoHeading = styled(UnstyledHeading)`
  text-align: ${(props) => props.type === 'vertical' ? 'center' : 'left'};
  padding: 0 0 10px;
`;

export const VideoCaption = styled(UnstyledCaption)`
  padding: 10px 0;
  text-align: ${(props) => props.type === 'vertical' ? 'center' : 'left'};
`;

export const AbsolutelyPositionedPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const RelativePositionWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;
