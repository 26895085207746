import React from 'react';
import styled, { keyframes } from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import useTypewriter from '@utils/useTypewriter';

const blink = keyframes`
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const Cursor = styled.span`
  font-weight: ${weights.NORMAL};
  margin-left: 2px;
  animation: ${blink} 1s linear infinite forwards;
`;

const Span = styled.span`
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  color: ${colors.G200};
  font-family: ${props => fonts(props.theme.locale).HANDWRITTEN};
  font-size: 32px;
  line-height: 100%;
  pointer-events: none;
`;

function Typewriter({ strings }) {
  const currentString = useTypewriter({
    afterTypingDelay: 2000,
    words: strings,
  });

  return (
    <Span aria-disabled="true">
      {currentString}
      <Cursor>|</Cursor>
    </Span>
  );
}

export default Typewriter;
