import styled from 'styled-components';

import colors from '@utils/colors';

export const Item = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${colors.B100};
`;

export const Wrapper = styled.div``;
