import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styles';
import {
  FacebookEmbed,
  InstagramEmbed,
  PinterestEmbed,
  TikTokEmbed,
  XEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed';

function SocialMediaEmbed(props) {
  const {
    title,
    description,
    type,
  } = props;

  let embed;
  switch (type) {
    case 'socialMediaFacebook':
      embed = props.socialMediaFacebook.map((item) =>
        <FacebookEmbed key={item._key}
                       url={item.url}
                       width={item.width || 328}
                       height={item.height || null} />,
      );

      break;
    case 'socialMediaInstagram':
      embed = props.socialMediaInstagram.map((item) =>
        <InstagramEmbed key={item._key}
                        url={item.url}
                        width={item.width || 328}
                        height={item.height || null} />,
      );
      break;
    case 'socialMediaPinterest':
      embed = props.socialMediaPinterest.map((item) =>
        <PinterestEmbed key={item._key}
                        url={item.url}
                        width={item.width || 328}
                        height={item.height || 550} />,
      );
      break;
    case 'socialMediaTikTok':
      embed = props.socialMediaTikTok.map((item) =>
        <TikTokEmbed key={item._key}
                     url={item.url}
                     width={item.width || 328}
                     height={item.height || null} />,
      );
      break;
    case 'socialMediaX':
      embed = props.socialMediaX.map((item) =>
        <XEmbed key={item._key}
                url={item.url}
                width={item.width || 328}
                height={item.height || null} />,
      );
      break;
    case 'socialMediaYouTube':
      embed = props.socialMediaYouTube.map((item) =>
        <YouTubeEmbed key={item._key}
                      url={item.url}
                      width={item.width || 328}
                      height={item.height || 585}
        />,
      );
      break;
    default:
      embed = null;
  }
  return (
    <styled.Wrapper>
      <styled.Inner>
        <styled.Title levels={[300, 200]} className='main'>{title}</styled.Title>
        <styled.Description levels={[300, 200]} className='main'>{description}</styled.Description>
        <styled.Items>{embed}</styled.Items>
      </styled.Inner>
    </styled.Wrapper>
  );
}


SocialMediaEmbed.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  socialMediaFacebook: PropTypes.arrayOf({ url: PropTypes.string, width: PropTypes.string, height: PropTypes.string }),
  socialMediaInstagram: PropTypes.arrayOf({ url: PropTypes.string, width: PropTypes.string, height: PropTypes.string }),
  socialMediaPinterest: PropTypes.arrayOf({ url: PropTypes.string, width: PropTypes.string, height: PropTypes.string }),
  socialMediaTikTok: PropTypes.arrayOf({ url: PropTypes.string, width: PropTypes.string, height: PropTypes.string }),
  socialMediaX: PropTypes.arrayOf({ url: PropTypes.string, width: PropTypes.string, height: PropTypes.string }),
  socialMediaYouTube: PropTypes.arrayOf({ url: PropTypes.string, width: PropTypes.string, height: PropTypes.string }),
};

export default SocialMediaEmbed;
