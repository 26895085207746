import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

export const Contact = styled.div`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.B100};
`;

export const Email = styled.a`
  color: ${colors.B500};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 12px;
  font-weight: ${weights.BOLD};
  line-height: 120%;
  text-decoration: underline;
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
  margin-right: 16px;
  border-radius: 24px;
  overflow: hidden;
`;

export const Name = styled.p`
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 18px;
  line-height: 140%;
  font-weight: ${weights.BLACK};
`;

export const Wrapper = styled.div``;
