import React, { forwardRef, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import get from 'lodash/get';
import numeral from 'numeral';
import { useIntl } from '@utils/localize';

import colors from '@utils/colors';
import Button from '@components/Button';
import Container from '@components/Container';
import { useRecaptcha } from 'react-hook-recaptcha';
import {
  Field,
  FormError,
  Input,
  Label,
} from '@components/Contact/styles';
import { ChevronDown } from '@components/Icon';
import Space from '@components/Space';
import { Body, Heading } from '@components/type';
import * as styled from './styles';
import regions from './regions';

// @todo refactor
const sitekey = process.env.GATSBY_RECAPTCHA_PUBLIC_KEY;
const containerId = "test-recaptcha2";

const DonationItemsForm = forwardRef((props, ref) => {
  const intl = useIntl();

  const [stage, setStage] = useState(0);
  const [cart, setCart] = useState({});

  useEffect(() => {
    // if (stage > 0) {
      props.collectJsConfig();
    // }
  }, [stage])

  const {
    control,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      country: intl.formatMessage({ id: 'donation.defaultCountry' }),
    },
  });

  const [captchaResponse, setCaptchaResponse] = useState(null);
  const expiredCallback = () => setCaptchaResponse(null);
  const successCallback = (response) => {
    setCaptchaResponse(response);
  }
  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    sitekey,
    size: "normal",
  });

  const executeCaptcha = (e) => {
    e.preventDefault();
    return handleSubmit((values) => _handleSubmit({...values, captchaResponse}))();
  }

  const {
    country,
  } = watch([
    'country',
  ]);
  const getTotal = (cart, pretty= false) => {
    const val = Object.values(cart).reduce((ac, val) => ac + val.price*val.quantity, 0);

    return pretty ? numeral(val).format('0,0') : val;
  }

  const fieldTotals = getTotal(cart);

  const donationTotal =
    isNaN(fieldTotals) || !fieldTotals
      ? ''
      : numeral(fieldTotals).format('0,0');
  let periods = 1;
  const _handleSubmit = (values) => {
    props.onSubmit({
      ...values,
      amount: parseInt((donationTotal || '0').replace(',', ''), 10),
      cart: Object.keys(cart).map(key => ({'heading': key, ...cart[key]})),
      payments: periods,
    });
  };

  const itemChangeHandler = (ev, item) => {
    let newCart = {...cart};
    const val = ev.target.value;

    if (val.length === 0) return false;
    newCart[item.heading] = {
      price: item.price,
      quantity: parseInt(ev.target.value)
    };

    setCart(newCart);
  }
  const successContactEmail = intl.formatMessage({ id: 'donation.success.contactEmail' });

  return (
    <styled.Wrapper>
      <styled.CalendarStyles />
      <Container centered columns={[12, 6]}>
        {props.heading && (
          <>
            <Heading levels={[400, 200]}>{props.heading}</Heading>
            {props.description && <Body>{props.description}</Body>}
          </>
        )}
        <Space size="xsmall" />
        {!props.transactionId && (
        <>
          <styled.ItemsContainer>
            <styled.ItemWrapper>
              <styled.ItemQuantityCol>
                <styled.ItemQuantityHeading>
                  Quantity
                </styled.ItemQuantityHeading>
              </styled.ItemQuantityCol>
              <styled.ItemPriceCol>&nbsp;</styled.ItemPriceCol>
              <styled.ItemHeadingCol>&nbsp;</styled.ItemHeadingCol>
            </styled.ItemWrapper>
            {props.items.map((item, key) => (
              <styled.ItemWrapper key={key}>
                <styled.ItemQuantityCol>
                  <Field>
                      <Input
                        disabled={!item.isEnabled}
                        color={colors.B100}
                        onChange={(ev) => itemChangeHandler(ev, item)}
                        name="items[]"
                      />
                  </Field>
                </styled.ItemQuantityCol>
                <styled.ItemPriceCol isDisabled={!item.isEnabled}>
                  ${numeral(item.price).format('0,0')}
                </styled.ItemPriceCol>
                <styled.ItemHeadingCol isDisabled={!item.isEnabled}>
                  {item.heading}
                  <styled.ItemSubHeading isDisabled={!item.isEnabled}>
                    {item.subheading}
                  </styled.ItemSubHeading>
                </styled.ItemHeadingCol>
              </styled.ItemWrapper>
            ))}
            <styled.ItemsTotalContainer>
              <Field>
                <Label>
                  {intl.formatMessage({ id: 'donation.form.label.total' })}
                  <styled.InputWrapper locked>
                    <styled.DollarSign>$</styled.DollarSign>
                    <Input
                      color={colors.B100}
                      disabled
                      name="total"
                      value={getTotal(cart, true)}
                    />
                  </styled.InputWrapper>
                </Label>
              </Field>
            </styled.ItemsTotalContainer>
            {/*<styled.CompleteDonationButtonWrapper>*/}
            {/*  <Button appearance="blue"*/}
            {/*          isDisabled={*/}
            {/*            captchaResponse === null*/}
            {/*          }*/}
            {/*          onClick={() => {*/}
            {/*      getTotal(cart) !== 0 && setStage(1);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Complete your donation*/}
            {/*  </Button>*/}
            {/*</styled.CompleteDonationButtonWrapper>*/}
          </styled.ItemsContainer>
          <Space size="xsmall" />
        </>
        )}
        {/*{stage > 0 && getTotal(cart) > 0 && (*/}
        {
          <styled.FormWrapper>
          {props.transactionId ? (
            <styled.Success ref={ref}>
              <styled.Heading levels={[400, 200]}>
                {intl.formatMessage({ id: 'donation.success.heading' })}
              </styled.Heading>
              <Body>
                <styled.SuccessBlockParagraph>
                  {intl.formatMessage({ id: 'donation.success.body' })}{' '}
                  {props.transactionId}
                </styled.SuccessBlockParagraph>
                <styled.SuccessBlockParagraph>
                  {intl.formatMessage({ id: 'donation.success.body2' })}
                </styled.SuccessBlockParagraph>
                <styled.SuccessBlockParagraph>
                  {intl.formatMessage({ id: 'donation.success.body3' })}
                  <a href={"mailto://" + successContactEmail}>{successContactEmail}</a>
                </styled.SuccessBlockParagraph>
              </Body>
            </styled.Success>
          ) : (
            <form onSubmit={executeCaptcha}>
              {/*<Input*/}
              {/*  hidden={true}*/}
              {/*  name="totalAmount"*/}
              {/*  value={getTotal(cart, true)}*/}
              {/*  ref={register({*/}
              {/*    required: true,*/}
              {/*  })}*/}
              {/*/>*/}
              <styled.Section>
                <styled.Heading level={400}>
                  {intl.formatMessage({
                    id: 'donation.form.section.donorDetails',
                  })}
                </styled.Heading>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.firstName') && errors.firstName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.firstName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.firstName') &&
                          errors.firstName
                        }
                        name="firstName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.firstName') && errors.firstName && (
                      <FormError>
                        {intl.formatMessage({
                          id: 'donation.form.error.firstName',
                        })}
                      </FormError>
                    )}
                  </Field>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.lastName') && errors.lastName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.lastName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.lastName') && errors.lastName
                        }
                        name="lastName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.lastName') && errors.lastName && (
                      <FormError>
                        {intl.formatMessage({
                          id: 'donation.form.error.lastName',
                        })}
                      </FormError>
                    )}
                  </Field>
                </styled.Fieldset>
                <Field>
                  <Label>
                    {intl.formatMessage({
                      id: 'donation.form.label.maidenName',
                    })}
                    <Input
                      color={colors.B100}
                      name="maidenName"
                      ref={register}
                    />
                  </Label>
                </Field>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={get(formState, 'touched.email') && errors.email}
                    >
                      {intl.formatMessage({ id: 'donation.form.label.email' })}*
                      <Input
                        color={colors.B100}
                        error={get(formState, 'touched.email') && errors.email}
                        name="email"
                        type="email"
                        ref={register({
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.email') && errors.email && (
                      <FormError>
                        {intl.formatMessage({
                          id: 'donation.form.error.email',
                        })}
                      </FormError>
                    )}
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.phone' })}
                      <Controller
                        as={Input}
                        color={colors.B100}
                        control={control}
                        name="phone"
                        type="tel"
                      />
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.address1',
                      })}
                      <Input
                        color={colors.B100}
                        name="address1"
                        ref={register}
                      />
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.address2',
                      })}
                      <Input
                        color={colors.B100}
                        name="address2"
                        ref={register}
                      />
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.city' })}
                      <Input color={colors.B100} name="city" ref={register} />
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.state' })}
                      {country === 'US' || country === 'CA' ? (
                        <styled.SelectWrapper>
                          <styled.Select name="region" ref={register}>
                            <option value=""></option>
                            {regions.territories[country].map((territory) => (
                              <option
                                key={territory.value}
                                value={territory.value}
                              >
                                {territory.label}
                              </option>
                            ))}
                          </styled.Select>
                          <ChevronDown />
                        </styled.SelectWrapper>
                      ) : (
                        <Input
                          color={colors.B100}
                          name="region"
                          ref={register}
                        />
                      )}
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset size={[1, 2]}>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.zip' })}
                      <Input
                        color={colors.B100}
                        error={get(formState, 'touched.zip') && errors.zip}
                        name="zip"
                        ref={register}
                      />
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.country',
                      })}
                      <styled.SelectWrapper>
                        <styled.Select name="country" ref={register}>
                          <option value=""></option>
                          {regions.countries.map((country) => (
                            <option key={country.value} value={country.value}>
                              {country.label}
                            </option>
                          ))}
                        </styled.Select>
                        <ChevronDown />
                      </styled.SelectWrapper>
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.affiliation') &&
                        errors.affiliation
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.affiliation',
                      })}
                      *
                      <styled.SelectWrapper>
                        <styled.Select
                          name="affiliation"
                          ref={register({ required: true })}
                        >
                          <option value="Alumna">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.alumna',
                            })}
                          </option>
                          <option value="Current Parent">
                            {intl.formatMessage({
                              id:
                                'donation.form.field.affiliation.currentParent',
                            })}
                          </option>
                          <option value="Parent of Alumna">
                            {intl.formatMessage({
                              id:
                                'donation.form.field.affiliation.parentOfAlumna',
                            })}
                          </option>
                          <option value="Grandparent">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.grandparent',
                            })}
                          </option>
                          <option value="Faculty">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.faculty',
                            })}
                          </option>
                          <option value="Friend">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.friend',
                            })}
                          </option>
                          <option value="Staff">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.staff',
                            })}
                          </option>
                          <option value="Other">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.other',
                            })}
                          </option>
                        </styled.Select>
                        <ChevronDown />
                      </styled.SelectWrapper>
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.classYear',
                      })}
                      <Input
                        color={colors.B100}
                        name="classYear"
                        maxLength={4}
                        ref={register}
                      />
                    </Label>
                  </Field>
                </styled.Fieldset>
              </styled.Section>
              <styled.ItemsTotalContainer>
                <Field>
                  <Label>
                    {intl.formatMessage({ id: 'donation.form.label.total' })}
                    <styled.InputWrapper locked>
                      <styled.DollarSign>$</styled.DollarSign>
                      <Input
                        color={colors.B100}
                        disabled
                        name="total"
                        value={getTotal(cart, true)}
                      />
                    </styled.InputWrapper>
                  </Label>
                </Field>
              </styled.ItemsTotalContainer>
              <Space size="xsmall" />
              <styled.Section>
                <styled.Heading level={400}>
                  {intl.formatMessage({
                    id: 'donation.form.section.paymentInformation',
                  })}
                </styled.Heading>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.ccFirstName') &&
                        errors.ccFirstName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.firstName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.ccFirstName') &&
                          errors.ccFirstName
                        }
                        name="ccFirstName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.ccFirstName') &&
                      errors.ccFirstName && (
                        <FormError>
                          {intl.formatMessage({
                            id: 'donation.form.error.firstName',
                          })}
                        </FormError>
                      )}
                  </Field>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.ccLastName') &&
                        errors.ccLastName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.lastName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.ccLastName') &&
                          errors.ccLastName
                        }
                        name="ccLastName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.ccLastName') &&
                      errors.ccLastName && (
                        <FormError>
                          {intl.formatMessage({
                            id: 'donation.form.error.lastName',
                          })}
                        </FormError>
                      )}
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.cardNumber',
                      })}
                      *
                      <div id="ccnumber" />
                    </Label>
                  </Field>
                  <styled.Fieldset>
                    <Field>
                      <Label>
                        {intl.formatMessage({
                          id: 'donation.form.label.cardExpiration',
                        })}
                        *
                        <div id="ccexp" />
                      </Label>
                    </Field>
                    <Field>
                      <Label>
                        {intl.formatMessage({
                          id: 'donation.form.label.cardSecurityCode',
                        })}
                        *
                        <div id="cvv" />
                      </Label>
                    </Field>
                  </styled.Fieldset>
                </styled.Fieldset>
              </styled.Section>
              <styled.CaptchaContainer>
                <div id={containerId} />
              </styled.CaptchaContainer>
              {props.error && <FormError>{props.error}</FormError>}
              <styled.Submit>
                <Button
                  fill
                  isDisabled={
                    captchaResponse === null ||
                    props.loading ||
                    parseInt((donationTotal || '0').replace(',', ''), 10) <= 0
                  }
                  type="submit"
                >
                  {props.loading
                    ? intl.formatMessage({ id: 'donation.form.submit.loading' })
                    : intl.formatMessage({
                        id: 'donation.form.submit.default',
                      })}
                </Button>
              </styled.Submit>
            </form>
          )}
        </styled.FormWrapper>
        }
      </Container>
    </styled.Wrapper>
  );
});

export default DonationItemsForm;
