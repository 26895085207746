/* global $crisp */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useIntl } from '@utils/localize';

import Image from '@components/Image';
import { Heading } from '@components/type';
import * as styled from './styles';

function SidebarChat({ heading, images, colorTheme }) {
  const intl = useIntl();

  return (
    <styled.Wrapper colorTheme={colorTheme}>
      {get(images, 'length') > 0 && (
        <styled.ImagesWrapper>
          {get(images, '[0].asset.fluid') && (
            <styled.ImageWrapper>
              <Image edge={0} fluid={images[0].asset.fluid} />
            </styled.ImageWrapper>
          )}
          {get(images, '[1].asset.fluid') && (
            <styled.ImageWrapper>
              <Image edge={0} fluid={images[1].asset.fluid} />
            </styled.ImageWrapper>
          )}
        </styled.ImagesWrapper>
      )}
      <div>
        <Heading level={500}>{heading}</Heading>
        <styled.Link
          onClick={() => {
            $crisp.push(['do', 'chat:show']);
            $crisp.push(['do', 'chat:open']);
            $crisp.push(['on', 'chat:closed', () => {
              $crisp.push(['do', 'chat:hide']);
            }]);
          }}
        >
          {intl.formatMessage({ id: 'chatWithUs' })}
        </styled.Link>
      </div>
    </styled.Wrapper>
  );
}

SidebarChat.propTypes = {
  heading: PropTypes.string.isRequired,
  images: PropTypes.array,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

SidebarChat.defaultProps = {
  colorTheme: 'blue',
};

export default SidebarChat;
