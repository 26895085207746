import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { isExternalUrl } from '@utils/string';
import Image from '@components/Image';
import { Heading, Body } from '@components/type';
import * as styled from './styles';

function SidebarLink({ heading, description, image, link, linkLabel, colorTheme }) {
  if (image && image.asset && image.asset.fluid && image.asset.fluid.sizes) {
    image.asset.fluid.sizes = '10vw';
  }
  return (
    <styled.Wrapper colorTheme={colorTheme}>
      {get(image, 'asset.fluid') && (
        <styled.ImageWrapper>
          <Image edge={0} fluid={image.asset.fluid} />
        </styled.ImageWrapper>
      )}
      <div>
        <Heading level={500}>{heading}</Heading>
        {description && <Body size={'small'}>{description}</Body>}
        {isExternalUrl(link.href) ? (
          <styled.ExternalLink href={link.href} colorTheme={colorTheme}>
            {link.label}
          </styled.ExternalLink>
        ) : (
          <styled.Link to={link.href} colorTheme={colorTheme}>
            {link.label}
          </styled.Link>
        )}
      </div>
    </styled.Wrapper>
  );
}

SidebarLink.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

SidebarLink.defaultProps = {
  colorTheme: 'blue',
};

export default SidebarLink;
