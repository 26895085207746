import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Image from '@components/Image';
import SidebarHeading from '../Heading';
import { Heading } from '@components/type';
import * as styled from './styles';
import emailHandler from '../emailHandler';

function Contacts({ contacts, heading }) {
  return (
    <styled.Wrapper>
      <SidebarHeading>{heading}</SidebarHeading>
      {contacts.map((contact, idx) => (
        <styled.Contact key={idx}>
          {get(contact, 'image.asset.fluid') && (
            <styled.ImageWrapper>
              <Image fluid={contact.image.asset.fluid} />
            </styled.ImageWrapper>
          )}
          <div>
            <Heading level={500}>{contact.name}</Heading>
            <styled.Email href={`mailto:${contact.email}`}>
              Email me
            </styled.Email>
          </div>
        </styled.Contact>
      ))}
    </styled.Wrapper>
  );
}

Contacts.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.object,
    name: PropTypes.string,
    email: PropTypes.string,
  })),
  heading: PropTypes.string.isRequired,
};

export default Contacts;
