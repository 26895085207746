import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Instagram from './component';

function InstagramProvider({ title }) {
  return null;
  /*
  return (
    <StaticQuery
      query={graphql`
        query InstagramQuery {
          allInstaNode(limit: 8) {
            edges {
              node {
                localFile {
                  childImageSharp {
                    fixed(width: 300, height: 300) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const images = data.allInstaNode.edges.map(({ node }) => node.localFile.childImageSharp.fixed);
        return <Instagram images={images} title={title} />;
      }}
    />
  );
   */
}

export default InstagramProvider;
