const formula = (familyIncome, familySize, assets, boardingStatus, numberOfStudents) => {
  let fedincomeprot = 0;
  let allowances = 0;
  let familycont = 0;
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // This group of if statments is uses to calculate Income Protection based on family size and
  // # of students paying tuition in the household.
  // Numbers were originally taken from FAFSA
  // These numbers can be adjusted if needed as they usually change slightly each year (the change
  // shouldn't impact the accuracy too much)
  // This Income Protection is ONLY used to calculate federal tax calculation
  // SSS uses a different income protection which will be accounted for later
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  if(familySize>6){
    if(numberOfStudents>5)
      fedincomeprot=27470+4470*(familySize-6)+3250*(numberOfStudents-5);
    else if (numberOfStudents === 5)
      fedincomeprot=26760+4450*(familySize-6);
    else if(numberOfStudents === 4)
      fedincomeprot=30720+4470*(familySize-6);
    else if(numberOfStudents === 3)
      fedincomeprot=33980+4470*(familySize-6);
    else if(numberOfStudents === 2)
      fedincomeprot=37230+4470*(familySize-6);
    else if(numberOfStudents === 1)
      fedincomeprot=39430+4470*(familySize-6);
  }
  else if (familySize === 6){
    if(numberOfStudents === 5)
      fedincomeprot=26760;
    else if(numberOfStudents === 4)
      fedincomeprot=29920;
    else if(numberOfStudents === 3)
      fedincomeprot=33100;
    else if(numberOfStudents === 2)
      fedincomeprot=36260;
    else if(numberOfStudents === 1)
      fedincomeprot=39430;
  }
  else if(familySize === 5){
    if(numberOfStudents === 4)
      fedincomeprot=24200;
    else if(numberOfStudents === 3)
      fedincomeprot=27380;
    else if(numberOfStudents === 2)
      fedincomeprot=30540;
    else if(numberOfStudents === 1)
      fedincomeprot=33720;
  }
  if(familySize === 4){
    if(numberOfStudents === 3)
      fedincomeprot=22240;
    else if(numberOfStudents === 2)
      fedincomeprot=25400;
    else if(numberOfStudents === 1)
      fedincomeprot=28580;
  }
  else if(familySize === 3){
    if(numberOfStudents === 2)
      fedincomeprot=19980;
    else if(numberOfStudents === 1)
      fedincomeprot=23140;
  }
  else if(familySize === 1)
    fedincomeprot=18580;

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // This group of if statments is used to Social Security Tax
  // Numbers taken from federal tax records/info
  // These numbers can be adjusted if needed as they usually change slightly each year (the change
  // shouldn't impact the accuracy too much)
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  if(familyIncome > 200000)
    allowances+=11140+0.0235*familyIncome;
  else if(familyIncome > 132900)
    allowances+=10167+0.0145*familyIncome;
  else
    allowances+=0.062*familyIncome;
  // allowances=Social Security Tax

  allowances+=0.11*familyIncome;
  // This accounts for State and Other Tax
  // Allowances= Social Security Tax + 11% of family income
  // (11% number taken as a VERY rough average of MA and NY)

  let taxableincome=familyIncome-fedincomeprot-allowances;
  // taxableincome= income - federal income prot - social security tax - state and other tax

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // This group of if statments is used to calculate the federal income tax
  // Numbers taken from federal tax records/info
  // These numbers can be adjusted if needed as they usually change slightly each year (the change
  // shouldn't impact the accuracy too much)
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  if(taxableincome>518400)
    allowances+=156235+0.37*(taxableincome-518400)+0.0145*taxableincome;
  else if(taxableincome>207351)
    allowances+=47367+0.35*(taxableincome-207350)+0.0145*taxableincome;
  else if(taxableincome>163300)
    allowances+=33271+0.32*(taxableincome-163300)+0.0145*taxableincome;
  else if(taxableincome>85585)
    allowances+=15000+0.24*(taxableincome-85525)+0.0145*taxableincome;
  else if(taxableincome>40126)
    allowances+=5000+0.22*(taxableincome-40126)+0.0145*taxableincome;
  else if(taxableincome>9876)
    allowances+=1000+0.12*(taxableincome-9876)+0.0145*taxableincome;
  else
    allowances+=0.1*taxableincome;
  //allowances=social security tax + state and other tax + fed tax

  allowances+=6388;
  //This number is from SSS

  let adjustedincome=familyIncome-allowances;
  if(assets>=27500)
    adjustedincome+=assets*0.02;
  // adds assets into income
  // adjustedincome=familyIncome-allowances+2% of assets
  // 2% is approx. Found by using data points from SSS

  let revisedadjustedincome=adjustedincome*0.88;
  // SSS seems to adjust income again on top of what FAFSA does
  // This formula is made by analyzing SSS data points

  let incomeprotect=8887.86*familySize+13130.81;
  // Finds Income Protection used in SSS
  // This is DIFFERENT than federal Income Protection
  // Formula from SSS data points

  let discincome=revisedadjustedincome-incomeprotect;
  // calculates discretionary income

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // This group of if statments takes discretionary income and calculates family contribution
  // Numbers were originally taken from FAFSA and then adjusted if needed to more closely match SSS
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  if(discincome>34500)
    familycont=(discincome-35100)*0.47+9494;
  else if(discincome>30100)
    familycont=(discincome-35100)*0.4+7583;
  else if (discincome>25700)
    familycont=6087+0.34*(discincome-25700);
  else if (discincome>21400)
    familycont=4840+0.29*(discincome-21400);
  else if (discincome>17000)
    familycont=3740+0.25*(discincome-17000);
  else if(discincome>5000)
    familycont=0.22*discincome;
  else if (discincome<5000)
    familycont=-750;
  familycont*=1.1;

  //Below accounts for boarding status
  if(boardingStatus === 1)
    familycont=familycont*1.1+2442;

  //Below accounts for multiple students in one family
  familycont=familycont/numberOfStudents;

  //Below accounts for the maximum tuition price
  if(boardingStatus === 1 && familycont>=62400)
    familycont=62400;
  else if(boardingStatus === 0 &&familycont>=37850)
    familycont=37850;

  return familycont;
}

export default formula;
