import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import isAfter from 'date-fns/isAfter';
import { useIntl } from '@utils/localize';

import getLocalizedData from '@utils/localize';
import Events from './component';

function EventProvider(props) {
  const rawData = useStaticQuery(graphql`
    query UpcomingEventsQuery {
      allSanityEvent(sort: { fields: startTime, order: ASC }) {
        edges {
          node {
            id
            category {
              title {
                en
                vi
                zh_Hans
                _type
              }
            }
            slug {
              current
            }
            startTime
            title {
              en
              vi
              zh_Hans
              _type
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);

  const upcomingEvents =
    data &&
    data.allSanityEvent.edges
      .map(({ node }) => ({
        ...node,
        title: node.title,
        startTime: new Date(node.startTime),
      }))
      .filter((e) => isAfter(e.startTime, new Date()))
      .slice(0, 3);

  return <Events events={upcomingEvents} {...props} />;
}

export default EventProvider;
