export const templateIds = {
  DONATE_INTERNAL: 'd-6624384066704646ac5d4b0deb9e3d6d',
  DONATE_THANK_YOU: 'd-28c34ad8f8fd46f78f2f5579daad8716',
  DONATE_ITEMS_THANK_YOU: 'd-68b4da7ce32c4228bb6119e0887cb2e0',
  HOMEPAGE_CONTACT_AUTO_RESPONSE: 'd-60c2d28e4abd48caa9d5a7a443655893',
  HOMEPAGE_CONTACT_STAFF: 'd-3d20484ef49545afaedde4e2fcade7ca',
  HORIZONS_PICKER_ONE: 'd-bdc4fd4c0d4d47babfcba4125f6303f5',
  HORIZONS_INTEREST_STAFF: 'd-704a87d31373461680b55608e24d5d52',
  HORIZONS_PICKER_EMAIL_ME: 'd-40a8a2d22e7d4df2b91cb421101a86f9',
  HORIZONS_PICKER_NEW_ONE: 'd-81504342f2974c43bb2880f2f7802a30',
};

function emailHandler({ templateId }) {
  const handleSend = async (values) => {
    await fetch('/.netlify/functions/email', {
      method: 'POST',
      body: JSON.stringify({ ...values, templateId }),
    });
  };

  return { send: handleSend };
}

export default emailHandler;
