import styled from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { Caption } from '@components/type';

export const CurrentTime = styled.div`
  position: absolute;
  top: -7px;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 3px solid ${colors.N0};
  background-color: ${(props) => getThemeStyles(props.colorTheme).text};
  pointer-events: none;
`;

export const FileDetails = styled.div`
  margin-bottom: 16px;

  ${mq.gtsm`
    margin-bottom: 24px;
  `}
`;

export const PlayButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  appearance: none;
  background: transparent;
  border: 0;
  height: 40px;
  width: 40px;
  outline: 0;
  cursor: pointer;

  ${mq.gtsm`
    height: 48px;
    width: 48px;
  `}

  & > svg:first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    fill: ${(props) => getThemeStyles(props.colorTheme).background};

    ${mq.gtsm`
      height: 48px;
      width: 48px;
    `}
  }

  & > svg:last-child {
    position: relative;
    height: 18px;
    width: 18px;
    fill: ${(props) => getThemeStyles(props.colorTheme).text};
    stroke: ${(props) => getThemeStyles(props.colorTheme).text};
    transition: transform 150ms ease;
  }

  &:hover > svg:last-child {
    transform: scale(1.15);
  }
`;

export const Player = styled.div`
  display: flex;
  align-items: center;
`;

export const Progress = styled.div`
  flex: 1;
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) => getThemeStyles(props.colorTheme).background};
  overflow: hidden;
  cursor: pointer;
`;

export const ProgressBar = styled.div`
  height: 10px;
  border-radius: 10px;
  background-color: ${(props) => getThemeStyles(props.colorTheme).text};
`;

export const ProgressWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  touch-action: none;
`;

export const Time = styled(Caption)`
  font-feature-settings: 'tnum';
  margin: 0 16px;
`;

export const Uploaded = styled(Caption)`
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px 0;
  border-top: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};

  ${mq.gtsm`
    padding: 24px 0;
  `}
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        button: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        button: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        button: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        button: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        button: colors.Y500,
        text: colors.B500,
      };
  }
}
