import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

export const ImageWrapper = styled.div`
  position: relative;
  width: 48px;
  height: 48px;

  &:only-child {
    width: 80px;
    height: 80px;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  margin-right: 16px;

  & > *:not(:first-child) {
    margin-top: 16px;
    margin-left: -16px;
  }
`;

export const Link = styled.a`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  text-decoration: underline;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-top: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        text: colors.B500,
      };
  }
}
