import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useIntl } from '@utils/localize';

import colors from '@utils/colors';
import { dateLocales } from '@utils/localize';
import Container from '@components/Container';
import { Caption, Heading } from '@components/type';
import { BlobTwo } from '@components/vector';
import Text from '../Text';

const Blob = styled.div`
  flex: 0 1 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 80px;
  width: 80px;
  margin: 32px 32px 32px 0;
  text-align: center;

  & > svg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 4px;
    z-index: -1;
  }
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 32px 0;
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
  white-space: pre-line;
`;

const Item = styled.div`
  position: relative;
  display: flex;

  &::after {
    content: '';
    position: absolute;
    left: calc(40px - 2px);
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: ${(props) => getThemeStyles(props.colorTheme).background};
  }

  &:first-child::after {
    height: 50%;
    bottom: 0;
  }

  &:last-child::after {
    height: 50%;
    top: 0;
  }
`;

function Schedule({ items, colorTheme }) {
  const intl = useIntl();

  return (
    <Container centered column={5}>
      {items.map((item, idx) => (
        <Item key={idx} colorTheme={colorTheme}>
          <Blob>
            <BlobTwo color={getThemeStyles(colorTheme).background} />
            <Heading level={200}>
              {format(parseISO(item.date), 'd', {
                locale: dateLocales[intl.locale],
              })}
            </Heading>
            <Caption>
              {format(parseISO(item.date), 'LLL', {
                locale: dateLocales[intl.locale],
              })}
            </Caption>
          </Blob>
          <Info colorTheme={colorTheme}>
            <Text text={item.description} noPadding />
          </Info>
        </Item>
      ))}
    </Container>
  );
}

export default Schedule;

function getThemeStyles(colorTheme) {
  switch (colorTheme) {
    case 'blue':
      return {
        background: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        text: colors.B500,
      };
  }
}
