import styled, { createGlobalStyle } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import UnstyledImage from '@components/Image';
import { Body, Caption, Heading as UnstyledHeading } from '@components/type';
import { RippedFive } from '@components/vector';

export const GlobalStyle = createGlobalStyle`
  .block--course-carousel .swiper-button-next,
  .block--course-carousel .swiper-button-prev {
    background-image: none;
    width: auto;
    height: auto;
    margin: 0;
  }

  .block--course-carousel .swiper-button-next {
    position: absolute;
    right: 24px;
    z-index: 1;
    top: 30%;

    ${mq.gtlg`
      right: 80px;
    `}
  }

  .block--course-carousel .swiper-button-prev {
    position: absolute;
    left: 24px;
    z-index: 1;
    top: 30%;

    ${mq.gtlg`
      left: 80px;
    `}
  }

  .block--course-carousel .swiper-button-disabled {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: ${H_PADDING_MOBILE};

  ${mq.gtmd`
    margin-left: ${(7 / 12) * 100}%;
  `}
`;

export const Content = styled.div`
  ${mq.gtsm`
    display: flex;
    justify-content: space-between;
  `}
`;

export const CourseHeading = styled(UnstyledHeading)`
  position: relative;
  z-index: 2;
  margin-left: 24px;
  margin-bottom: -8px;
  color: ${(props) => getThemeStyles(props.colorTheme).text};

  ${mq.gtmd`
    margin-bottom: -24px;
    margin-left: ${(7 / 12) * 100}%;
  `}
`;

export const Description = styled(Body)`
  ${mq.gtsm`
    flex: 1 0 ${(6 / 12) * 100}%;
  `}
`;

export const Edge = styled(RippedFive)`
  position: absolute;
  width: 102%;
  height: auto;
  bottom: 0;
  margin: 0 -2px -2px;

  fill: ${colors.N0};
`;

export const Eyebrow = styled(Caption)`
  margin-bottom: 8px;

  ${mq.gtsm`
    margin-bottom: 24px;
  `}
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
  padding-right: 24px;


  ${mq.gtsm`
    flex: 1 0 ${(5 / 12) * 100}%;
    margin-bottom: 0;
  `}
`;

export const Image = styled(UnstyledImage)`
  margin-bottom: 16px;

  ${mq.gtsm`
    margin-bottom: 32px;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const Item = styled.div`
  user-select: none;
  cursor: grab;
  padding-bottom: 32px;

  ${mq.gtsm`
    padding-bottom: 40px;
  `}
`;

export const Next = styled.div`
  display: none;

  ${mq.gtsm`
    display: block;
  `}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  & > svg {
    margin-right: -1px;
    margin-left: -1px;
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
  }
`;

export const Prev = styled.div`
  display: none;

  ${mq.gtsm`
    display: block;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        button: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        button: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        button: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        button: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        button: colors.Y500,
        text: colors.B500,
      };
  }
}
