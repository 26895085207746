import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useGoal } from 'gatsby-plugin-fathom'

import Button from '@components/Button';
import { RoughOne, RoughThree, Swirl } from '@components/vector';
import * as styled from './styles';

function ChatBlock({
  button,
  description,
  heading,
  image,
  imageExtended,
  outline,
  colorTheme,
}) {
  const themeStyles = styled.getThemeStyles(colorTheme);
  const onClickHandler = () => {
    button.fathomGoalId && useGoal(button.fathomGoalId, true)();

    $crisp.push(['do', 'chat:show']);
    $crisp.push(['do', 'chat:open']);
    $crisp.push([
      'on',
      'chat:closed',
      () => {
        $crisp.push(['do', 'chat:hide']);
      },
    ]);
  };
  let swirlColor;
  if (outline) {
     swirlColor = colorTheme === 'white' ? themeStyles.swirl : themeStyles.background;
  } else {
    swirlColor = themeStyles.fill;
  }

  return (
    <styled.Wrapper outline={outline} colorTheme={colorTheme}>
      <RoughThree />
      <styled.Inner outline={outline} colorTheme={colorTheme}>
        <Swirl color={swirlColor} />
        <styled.Images>
          {get(image, 'asset.fluid') && (
            <>
              <styled.Image credit={image.credit} fluid={image.asset.fluid} edge={1} ratio={3/5} altText={imageExtended.altText}/>
              {image.credit && <styled.Credit>{image.credit}</styled.Credit>}
            </>
          )}
        </styled.Images>
        <styled.Content>
          <styled.Heading level={200} colorTheme={colorTheme}>{heading}</styled.Heading>
          <styled.Description sizes={['small', 'regular']} {...{colorTheme}}>{description}</styled.Description>
          <Button appearance={button.colorTheme} fill={Boolean(outline)} onClick={onClickHandler}>
            {button.label}
          </Button>
        </styled.Content>
      </styled.Inner>
      <RoughOne />
    </styled.Wrapper>
  );
}

ChatBlock.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  outline: PropTypes.oneOf(['top', 'bottom', 'both']),
  colorTheme: PropTypes.oneOf(['dark-blue', 'green', 'orange', 'dark-purple', 'white']),
};

ChatBlock.defaultProps = {
  colorTheme: 'dark-blue',
};

export default ChatBlock;
