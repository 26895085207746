import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import colors from '@utils/colors';
import Button from '@components/Button';
import { Field, FormError, Input, Label } from '@components/Contact/styles';
import { Body } from '@components/type';
import { RoughOne } from '@components/vector';
import * as styled from './styles';

function InterestForm({ interest, onClear, onSubmit }) {
  const intl = useIntl();

  const { errors, formState, register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      interest,
    },
  });
  const [submitted, setSubmitted] = useState(false);

  const _handleSubmit = async (values) => {
    await onSubmit(values);
    setSubmitted(true);
  };

  useEffect(() => {
    let submitTimeout;

    if (submitted) {
      submitTimeout = setTimeout(() => {
        setSubmitted(false);
        onClear();
      }, 5000);
    }

    return () => clearTimeout(submitTimeout);
  }, [submitted, onClear]);

  return (
    <styled.Wrapper>
      <styled.Inner>
        <AnimatePresence initial={false}>
          {submitted ? (
            <styled.Success
              key="submitted"
              initial="enter"
              animate="center"
              variants={{
                enter: {
                  opacity: 0,
                  x: '100%',
                },
                center: {
                  opacity: 1,
                  x: '0%',
                },
              }}
            >
              <styled.Heading level={200}>
                {intl.formatMessage({
                  id: 'horizonPicker.interestForm.success.heading',
                })}
              </styled.Heading>
              <Body>
                {intl.formatMessage({
                  id: 'horizonPicker.interestForm.success.body',
                })}
              </Body>
            </styled.Success>
          ) : (
            <motion.div
              key="form"
              animate="center"
              exit="exit"
              variants={{
                center: {
                  opacity: 1,
                  x: '0%',
                },
                exit: {
                  opacity: 0,
                  x: '-100%',
                },
              }}
            >
              <styled.Heading level={300}>
                {intl.formatMessage({
                  id: 'horizonPicker.interestForm.heading',
                })}
              </styled.Heading>
              <Body>
                {intl.formatMessage({ id: 'horizonPicker.interestForm.description' })}
              </Body>
              <styled.Form onSubmit={handleSubmit(_handleSubmit)}>
                <Field>
                  <Label>
                    {intl.formatMessage({
                      id: 'horizonPicker.interestForm.label.interest',
                    })}
                  </Label>
                  <Input color={colors.G200} name="interest" ref={register} />
                </Field>
                <Field>
                  <Label>
                    {intl.formatMessage({
                      id: 'horizonPicker.interestForm.label.name',
                    })}
                  </Label>
                  <Input color={colors.G200} name="name" ref={register} />
                </Field>
                <Field>
                  <Label
                    error={get(formState, 'touched.email') && errors.email}
                  >
                    {intl.formatMessage({
                      id: 'horizonPicker.interestForm.label.email',
                    })}
                    *
                  </Label>
                  <Input
                    error={get(formState, 'touched.email') && errors.email}
                    name="email"
                    type="email"
                    ref={register({
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                    color={colors.G200}
                  />
                  {get(formState, 'touched.email') && errors.email && (
                    <FormError>
                      {intl.formatMessage({
                        id: 'horizonPicker.interestForm.error.email',
                      })}
                    </FormError>
                  )}
                </Field>
                <Button
                  appearance="green"
                  isDisabled={!get(formState, 'isValid')}
                  isFullWidth
                  type="submit"
                >
                  {intl.formatMessage({
                    id: 'horizonPicker.interestForm.submit',
                  })}
                </Button>
              </styled.Form>
            </motion.div>
          )}
        </AnimatePresence>
      </styled.Inner>
      <RoughOne color={colors.G100} />
    </styled.Wrapper>
  );
}

export default InterestForm;
