import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

export const PersonContainer = styled.div`
  text-align: right;
`;
export const Quote = styled.div`
  font-family: ${props => fonts(props.theme.locale).SANS_CONDENSED};
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-size: ${(props) => props.isLarge ? '32px' : '20px'};
  line-height: ${(props) => props.isLarge ? '120%' : '110%'};
  font-weight: ${weights.BOLD};
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-top: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        text: colors.B500,
      };
  }
}
