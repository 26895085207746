import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useIntl } from '@utils/localize';
import styled from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import Button from '@components/Button';
import Container from '@components/Container';
import {
  Checkboxes,
  Checkbox,
  Field,
  FormError,
  Input,
  Label,
  Textarea,
} from '@components/Contact/styles';
import { Body, Heading } from '@components/type';

const ButtonWrapper = styled.div`
  text-align: center;
`;

const Fieldset = styled.div`
  width: 100%;

  & > * {
    width: 100%;
  }

  ${mq.gtsm`
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 32px;
    }
  `}
`;

const FormInner = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Success = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 64px 48px;
  text-align: center;

  & > button {
    margin-top: 32px;
  }

  & > p {
    margin-top: 8px;
  }
`;

const Title = styled(Heading)`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function ContactBlock({ heading, onSubmit }) {
  const intl = useIntl();

  const [interests, setInterests] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const { errors, formState, handleSubmit, register, reset } = useForm();

  const _handleSubmit = async (values) => {
    await onSubmit({ ...values, interests: interests.join(', ') });
    setSubmitted(true);
  };

  const handleClose = () => {
    reset();
    setInterests([]);
    setSubmitted(false);
  };

  const handleCheck = (name) => {
    setInterests(
      interests.includes(name)
        ? interests.filter((n) => n !== name)
        : [...interests, name],
    );
  };

  return (
    <Container centered column={6}>
      <Title level={200}>{heading}</Title>
      <Wrapper>
        <FormInner>
          <Success
            animate={submitted ? 'center' : 'enter'}
            initial="enter"
            variants={{
              enter: {
                opacity: 0,
                x: '100%',
              },
              center: {
                opacity: 1,
                x: '0%',
              },
            }}
          >
            <Heading levels={[400, 200]}>
              {intl.formatMessage({ id: 'contact.success.heading' })}
            </Heading>
            <Body>{intl.formatMessage({ id: 'contact.success.description' })}</Body>
            <Button appearance="blue" fill onClick={handleClose}>
              {intl.formatMessage({ id: 'contact.success.button' })}
            </Button>
          </Success>
          <motion.form
            animate={submitted ? 'exit' : 'center'}
            initial="center"
            variants={{
              center: {
                opacity: 1,
                x: '0%',
              },
              exit: {
                opacity: 0,
                x: '-100%',
              },
            }}
            onSubmit={handleSubmit(_handleSubmit)}
          >
            <Fieldset>
              <Field>
                <Label>
                  {intl.formatMessage({ id: 'contact.form.label.name' })}
                  <Input color={colors.B100} name="name" ref={register} />
                </Label>
              </Field>
              <Field>
                <Label
                  error={get(formState, 'touched.email') && errors.email}
                >
                  {intl.formatMessage({ id: 'contact.form.label.email' })}*
                  <Input
                    error={get(formState, 'touched.email') && errors.email}
                    name="email"
                    type="email"
                    ref={register({
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                  />
                </Label>
                {get(formState, 'touched.email') && errors.email && (
                  <FormError>
                    {intl.formatMessage({ id: 'contact.form.error.email' })}
                  </FormError>
                )}
              </Field>
            </Fieldset>
            <Fieldset>
              <Field>
                <Label>
                  {intl.formatMessage({ id: 'contact.form.label.phone' })}
                  <Input
                    color={colors.B100}
                    name="phone"
                    type="tel"
                    ref={register}
                  />
                </Label>
              </Field>
              <Field>
                <Label>{intl.formatMessage({ id: 'contact.form.label.interest' })}</Label>
                <Checkboxes>
                  <Checkbox
                    color={colors.B100}
                    checked={interests.includes('Applying')}
                    onClick={() => {
                      handleCheck('Applying');
                    }}
                  >
                    {intl.formatMessage({ id: 'contact.form.field.interest.applying' })}
                  </Checkbox>
                  <Checkbox
                    color={colors.B100}
                    checked={interests.includes('Visiting')}
                    onClick={() => {
                      handleCheck('Visiting');
                    }}
                  >
                    {intl.formatMessage({ id: 'contact.form.field.interest.visiting' })}
                  </Checkbox>
                  <Checkbox
                    color={colors.B100}
                    checked={interests.includes('Information')}
                    onClick={() => {
                      handleCheck('Information');
                    }}
                  >
                    {intl.formatMessage({ id: 'contact.form.field.interest.information' })}
                  </Checkbox>
                </Checkboxes>
              </Field>
            </Fieldset>
            <Field>
              <Label>
                {intl.formatMessage({ id: 'contact.form.label.message' })}
                <Textarea color={colors.B100} name="message" ref={register} />
              </Label>
            </Field>
            <ButtonWrapper>
              <Button appearance="blue" fill type="submit">
                {intl.formatMessage({ id: 'contact.form.submit' })}
              </Button>
            </ButtonWrapper>
          </motion.form>
        </FormInner>
      </Wrapper>
    </Container>
  );
}

export default ContactBlock;
