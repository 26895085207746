import React, { Component, createRef } from 'react';
import format from 'date-fns/format';
import { injectIntl } from 'gatsby-plugin-react-intl';
import numeral from 'numeral';

import colors from '@utils/colors';
import emailHandler, { templateIds } from '@utils/emailHandler';
import useScript from '@utils/useScript';
import Form from './component';

class DonationForm extends Component {
  constructor() {
    super();

    this.successRef = createRef();
  }

  state = {
    error: null,
    loading: false,
    values: {},
    transactionId: null,
  };

  collectJsConfig = () => {
    window.CollectJS.configure({
      variant: 'inline',
      callback: (token) => {
        this.finishSubmit(token);
      },
      fields: {
        ccnumber: {
          placeholder: '0000 0000 0000 0000',
          selector: '#ccnumber',
        },
        ccexp: {
          placeholder: '10 / 25',
          selector: '#ccexp',
        },
        cvv: {
          placeholder: '123',
          selector: '#cvv',
        },
      },
    });
  }

  finishSubmit = async (response) => {
    const { values } = this.state;
    const { send: sendThanks } = emailHandler({ templateId: templateIds.DONATE_ITEMS_THANK_YOU });
    const { send: sendStaff } = emailHandler({ templateId: templateIds.DONATE_INTERNAL });

    const token = response.token;

    const payload = {
      ...this.state.values,
      token,
    };

    const transactionVariables = {
      type: 'sale',
      payment_token: token,
      amount: payload.amount,
      first_name: payload.ccFirstName,
      last_name: payload.ccLastName,
      address1: payload.address1,
      address2: payload.address2,
      city: payload.city,
      state: payload.region,
      zip: payload.zip,
      country: payload.country,
      phone: payload.phone,
      email: payload.email,
      customer_receipt: true,
      captchaResponse: payload.captchaResponse,
      // ccnumber: '4111111111111111',
      // ccexp: '1025',
      // cvv: '999',
      // test_mode: 'enabled',
    };

    const res = await fetch('/.netlify/functions/donation', {
      method: 'POST',
      body: JSON.stringify(transactionVariables),
    });

    const data = await res.json();

    if (data.response !== '1') {
      this.setState({
        error: this.props.intl.formatMessage({
          id: data.error === 'INVALID_CAPTCHA' ? 'donation.form.error.captcha' : 'donation.form.error.submit'
        }),
        loading: false,
      });
    } else {
      this.setState(
        { loading: false, transactionId: data.transactionid },
        () => {
          if (this.successRef.current) {
            this.successRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        },
      );

      await Promise.all([
        sendThanks({
          sender: 'advancement@misshalls.org',
          recipient: values.email,
          variables: {
            ...values,
            amount: numeral(values.amount).format('0,0'),
          },
        }),
        sendStaff({
          sender: 'advancement@misshalls.org',
          recipient: 'donations@misshalls.org',
          variables: {
            ...values,
            amount: numeral(values.amount).format('0,0'),
            transactionId: data.transactionid,
            date: format(new Date(), 'M/d/yyyy'),
            annualFund: values.annualFund || '0',
            // communityActionFund: values.communityActionFund || '0',
            myReunionGift: values.myReunionGift || '0',
            givingTuesday: values.givingTuesday || '0',
            other: values.other || '0',
          },
        }),
      ]);
    }
  };

  handleClose = () => {
    this.setState({
      error: null,
      loading: false,
      values: {},
      transactionId: null,
    });
  };

  handleSubmit = (values) => {
    this.setState(
      { error: null, loading: true, values },
      window.CollectJS.startPaymentRequest,
    );
  };

  render() {
    return (
      <Form
        heading={this.props.heading}
        description={this.props.description}
        items={this.props.items}
        error={this.state.error}
        loading={this.state.loading}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
        collectJsConfig={this.collectJsConfig}
        recurringHeading={this.props.recurringHeading}
        recurringDescription={this.props.recurringDescription}
        transactionId={this.state.transactionId}
        ref={this.successRef}
      />
    );
  }
}

const WrappedDonationForm = injectIntl(DonationForm);

export default function ({
  heading,
  description,
  items,
  recurringHeading,
  recurringDescription,
}) {
  const [loaded] = useScript(
    'https://secure.safewebservices.com/token/Collect.js',
    {
      'data-tokenization-key': '688zV6-aG4NCx-2U77G7-XR3467',
      'data-custom-css': `{
        "height": "48px",
        "width": "100%",
        "padding": "0 8px",
        "appearance": "none",
        "font-size": "16px",
        "border": "2px solid ${colors.B100}",
        "border-radius": "8px",
        "outline": "0"
      }`,
      'data-invalid-css': `{
        "border-color": "${colors.R200}"
      }`,
      'data-focus-css': `{
        "border-color": "${colors.B200}"
      }`,
    },
  );

  if (loaded) {
    return (
      <WrappedDonationForm
        heading={heading}
        description={description}
        items={items}
        recurringHeading={recurringHeading}
        recurringDescription={recurringDescription}
      />
    );
  }

  return null;
}
