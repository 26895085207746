import React from 'react';
import styled from 'styled-components';

import Container from '@components/Container';
import { Body, Heading } from '@components/type';

const Item = styled.div`
  width: 50%;
  margin-bottom: 40px;
`;

const Name = styled(Heading)`
  margin-bottom: 8px;
`;

const Title = styled(Body)`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function Members({ items }) {
  return (
    <Container centered column={5}>
      <Wrapper>
        {items.map((item, idx) => (
          <Item key={idx}>
            <Name level={400}>{item.name}</Name>
            <Title>{item.title}</Title>
          </Item>
        ))}
      </Wrapper>
    </Container>
  );
}

export default Members;
