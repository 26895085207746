import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import * as styled from './styles';

function DoubleImageBlock({ credit, image1, image2, imageExtended1, imageExtended2 }) {
  if (!get(image1, 'asset.fluid') || !get(image2, 'asset.fluid')) {
    return null;
  }

  return (<>
    <styled.Wrapper>
      <styled.ImageWrapper>
        <styled.Image
          fluid={image1.asset.fluid}
          alt={imageExtended1.altText}
        />
        <styled.Image
          fluid={image2.asset.fluid}
          alt={imageExtended2.altText}
        />
      </styled.ImageWrapper>
      {(credit) && (
        <styled.DoubleCaption sizes={['small', 'regular']}>
          {credit && <em>{credit}</em>}
        </styled.DoubleCaption>
      )}
    </styled.Wrapper>
    </>
  );
}

DoubleImageBlock.propTypes = {
  caption: PropTypes.string,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
};

export default DoubleImageBlock;
