import React from 'react';

import TuitionCalculator from './component';

function TuitionCalculatorProvider(props) {
  return (
    <TuitionCalculator
      limits={{
        boarding: {
          lowerBound: props.boardingLowerBound,
          lowerDisplay: props.boardingLowerDisplay,
          monthlyLowerDisplay: props.boardingMonthlyLowerDisplay,
          upperBound: props.boardingUpperBound,
          upperDisplay: props.boardingUpperDisplay,
          monthlyUpperDisplay: props.boardingMonthlyUpperDisplay,
        },
        day: {
          lowerBound: props.dayLowerBound,
          lowerDisplay: props.dayLowerDisplay,
          monthlyLowerDisplay: props.dayMonthlyLowerDisplay,
          upperBound: props.dayUpperBound,
          upperDisplay: props.dayUpperDisplay,
          monthlyUpperDisplay: props.dayMonthlyUpperDisplay,
        },
      }}
      fathomGoalId={props.fathomGoalId}
    />
  );
}

export default TuitionCalculatorProvider;
