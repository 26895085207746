import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import UnstyledImage from '@components/Image';
import { Heading } from '@components/type';

export const Backdrop = styled.div`
  flex: 1;
  padding: ${H_PADDING_MOBILE};
  background-color: ${(props) => getThemeStyles(props.colorTheme).background};

  ${mq.gtsm`
    padding: 0 15vw;
  `}
`;

export const BackdropWrapper = styled.div`
  & > svg:first-of-type {
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    vertical-align: bottom;
  }

  & > svg:last-of-type {
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;
  }

  ${(props) =>
    props.hasImage &&
    css`
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    `}

  ${mq.gtsm`
    ${props => props.hasImage && css`
      top: 16px;
    `}
  `}
`;

export const Details = styled.div`
  margin-left: 16px;

  ${mq.gtsm`
    margin-left: 0;
  `}
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px ${H_PADDING_MOBILE};

  ${mq.gtsm`
    align-items: center;
    flex-direction: row;
  `}

  ${mq.gtmd`
    padding: 0 15vw;
  `}
`;

export const Image = styled(UnstyledImage)`
  width: 30%;

  ${mq.ltsm`
    padding-bottom: 30%;
  `}

  ${mq.gtsm`
    width: 100%;
  `}
`;

export const Name = styled(Heading)`
  margin-top: 24px;
  margin-bottom: 4px;
`;

export const Quote = styled.p`
  order: 1;
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).HANDWRITTEN};
  font-size: 24px;
  font-weight: ${weights.BOLD};
  letter-spacing: -0.02em;
  line-height: 120%;

  ${mq.gtsm`
    margin: ${(props) => (props.hasImage ? '16px' : 0)} 0 0 0;
    padding: 60px 0 ${(props) => ((props.hasImage || !props.hasStudent) ? '60px' : '24px')};
    font-size: 34px;
    line-height: 160%;
  `}
`;

export const Student = styled.div`
  display: flex;
  order: 1;
  margin-top: 36px;

  ${mq.gtsm`
    display: block;
    flex: 1 0 30%;
    align-self: ${(props) => props.hasImage ? 'flex-start' : 'center'};
    margin-top: ${(props) => props.hasImage ? '-16px' : 0};
    padding-bottom: ${(props) => props.hasImage ? 0 : '60px'};

    ${(props) =>
      props.align === 'left'
        ? css`
            order: 0;
            margin-right: ${(props) => props.hasImage ? '32px' : 0};
          `
        : css`
            order: 2;
            margin-left: ${(props) => props.hasImage ? '32px' : 0};
          `}
  `}
`;

export const Wrapper = styled.div`
  position: relative;

  ${mq.gtsm`
    padding-top: ${(props) => (props.hasImage ? '16px' : '0')};
    padding-bottom: ${(props) => (props.hasImage ? '60px' : '0')};
  `}
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        text: colors.B500,
      };
  }
}
