import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import UnstyledImage from '@components/Image';
import { Body, Heading as UnstyledHeading } from '@components/type';

export const Content = styled.div`
  position: relative;
  z-index: 1;

  ${mq.gtsm`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: ${ H_PADDING_MOBILE };
  `}
`;

export const Credit = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  font-weight: ${weights.BOLD};
  font-family: ${props => props.theme.fonts.SANS};
  font-size: 12px;

  ${props => props.imagesFlipped && css`
    left: 0;
    right: auto;
  `}
`;

export const Description = styled(Body)`
  margin-bottom: 32px;
  white-space: pre-line;
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
  color: ${(props) => getThemeStyles(props.colorTheme).text};

  ${mq.gtsm`
    margin-bottom: 16px;
  `}
`;

export const Image = styled(UnstyledImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ImageWrapper = styled.div`
  position: absolute;

  &:first-of-type {
    top: 0;
    right: 0;
    width: 65%;
    padding-bottom: 86.67%; /* 65% of 4/3 ratio */
  }

  &:last-of-type {
    bottom: 0;
    width: 50%;
    padding-bottom: 50%;
  }
`;

export const Images = styled.div`
  position: relative;
  margin-bottom: 48px;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  ${(props) =>
    props.flipped &&
    css`
      & > div:first-of-type {
        left: 0;
      }

      & > div:last-of-type {
        right: 0;
      }
    `}

  ${mq.gtmd`
    flex: 1 0 50%;
    margin-right: 96px;
    margin-bottom: 0;
  `}
`;

export const Inner = styled.div`
  position: relative;
  z-index: 1;
  padding: 48px ${H_PADDING_MOBILE};
  background-color: ${(props) =>
    props.outline ? colors.N0 : getThemeStyles(props.colorTheme).background};

  ${mq.gtmd`
    display: flex;
    padding: 60px calc((100vw - 1280px) / 2);
  `}

  & > svg {
    position: absolute;
    top: 20%;
    right: -200px;
    left: -100px;
    width: calc(100% + 300px);
    z-index: 0;

    ${mq.gtsm`
      top: ${props => props.flipped ? 'auto' : '0'};
      bottom: ${props => props.flipped ? '0' : 'auto'};
      right: 50%;
      left: 0;
      width: 50%;
    `}
  }
`;

export const Sizer = styled.span`
  display: block;

  &:first-of-type {
    width: 65%;
    padding-bottom: 86.67%; /* 65% of 4/3 ratio */
  }

  &:last-of-type {
    width: 50%;
    margin-top: -25%;
    padding-bottom: 50%;

    ${mq.gtsm`
      margin-top: -40%;
    `}
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;

  & > svg {
    z-index: 0;
  }

  & > svg:first-of-type {
    fill: ${(props) =>
      props.outline ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'top' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).background
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'top' || props.outline === 'both' ? '3px' : 0};
    vertical-align: bottom;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-bottom: -3px;
        margin-left: -3px;
      `}
  }

  & > svg:last-of-type {
    fill: ${(props) =>
      props.outline ? 'none' : getThemeStyles(props.colorTheme).background};
    stroke: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both'
        ? getThemeStyles(props.colorTheme).background
        : 'none'};
    stroke-width: ${(props) =>
      props.outline === 'bottom' || props.outline === 'both' ? '3px' : 0};
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;

    ${mq.gtsm`
      stroke-width: 1px;
    `}

    ${(props) =>
      props.outline &&
      css`
        width: calc(100% + 6px);
        margin-right: -3px;
        margin-top: -1px;
        margin-left: -3px;
      `}
  }
`;

export function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        fill: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        fill: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        fill: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        fill: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        fill: colors.Y200,
        text: colors.B500,
      };
  }
}
