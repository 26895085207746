import get from 'lodash/get';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

const cfg = {
  projectId: "yln0jo77",
  dataset: "production",
};

export default function getFluidImage(image, opts = {}) {
  return getFluidGatsbyImage(get(image, 'asset._id'), opts, cfg);
}
