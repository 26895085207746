import styled from 'styled-components';

import colors from '@utils/colors';
import { weights } from '@utils/fonts';

export const Category = styled.a`
  color: ${colors.O500};
  font-size: 12px;
  font-weight: ${weights.BOLD};
  line-height: 120%;
  text-decoration: underline;
`;

export const Event = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.O100};
`;

export const EventDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  padding-right: 16px;
  border-right: 1px solid ${colors.O100};
`;

export const Wrapper = styled.div``;
