import React from 'react';
import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import Container from '@components/Container';
import { Body, Heading } from '@components/type';

const Email = styled.a`
  color: ${colors.B500};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 20px;
  line-height: 24px;
  font-weight: ${weights.BOLD};
`;

const Item = styled.div`
  width: 50%;
  margin-bottom: 48px;
`;

const Name = styled(Heading)`
  margin-bottom: 8px;
`;

const Title = styled(Body)`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function Staff({ items }) {
  return (
    <Container centered column={5}>
      <Wrapper>
        {items.map((item, idx) => (
          <Item key={idx}>
            <Name level={300}>{item.name}</Name>
            <Title>{item.title}</Title>
            <Email href={`mailto:${item.email}`}>{item.email}</Email>
          </Item>
        ))}
      </Wrapper>
    </Container>
  );
}

export default Staff;
