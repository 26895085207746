import UnstyledImage from 'gatsby-image';
import styled  from 'styled-components';
import { Caption } from '@components/type';
import { mq } from '@utils/responsive';

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: top;

  ${mq.gtsm`
    flex-direction: row;
    justify-content: space-evenly;
  `}

`;

export const Image = styled(UnstyledImage)`
  flex-grow: 1;
  margin: 0 0 10px 0;

  ${mq.gtsm`
    margin: 5px
  `}
`;

export const DoubleCaption = styled(Caption)`
  ${mq.gtsm`
    margin: 5px
  `}
`;

export const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;

  ${mq.gtsm`
    width: ${(7 / 12) * 100}%;
    margin: 0 auto;
  `}
`;
