import styled from 'styled-components';

import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { Heading as UnstyledHeading } from '@components/type';

export const Body = styled.div`
  margin-bottom: 40px;

  ${mq.gtsm`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 0 60px;
    text-align: center;
  `}
`;

export const GridItem = styled.div`
  position: relative;
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 24px;

  ${mq.gtsm`
    margin-bottom: 40px;
  `}
`;

export const ImageGrid = styled.div`
  display: ${props => props.hideOnMobile ? 'none' : 'grid'};
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  ${mq.gtsm`
    display: grid;
    grid-gap: 32px;
    width: 25%;

    &:first-child {
      margin-left: -32px;
    }

    &:last-child {
      margin-right: -32px;
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 0 ${H_PADDING_MOBILE};

  ${mq.gtsm`
    flex-direction: row;
    padding: 0;
    overflow: hidden;
  `}
`;
