import styled from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { Heading as UnstyledHeading } from '@components/type';

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  padding-bottom: ${2 / 3 * 100}%;
`;

export const Loadmore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  ${mq.gtsm`
    grid-column: 1 / span 2;
  `}
`;

export const Result = styled.div`
  padding: 16px;
  background-color: ${colors.N0};
  border-radius: 8px;
  border: 3px solid ${colors.G100};
`;

export const Results = styled.div`
  display: grid;
  grid-template-columns: 1;
  grid-gap: 32px;
  margin-top: 32px;

  ${mq.gtsm`
    grid-template-columns: repeat(2, 1fr);
    margin-top: 56px;
  `}
`;

export const Wrapper = styled.div`

`;
