import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';

import { Heading } from '@components/type';
import { TextWrapper } from '../block/Text/styles';


export const Inner = styled.div`
  position: relative;
  z-index: 1;
`;

export const Title = styled(Heading)`
  margin-top: 24px;
  margin-bottom: 4px;
`;

export const Description = styled.div`
  margin-top: 24px;
  margin-bottom: 4px;
`;

export const Items = styled.div`
  margin-top: 24px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
`;

export const Icon = styled.div`
  top: calc(50% - 21px);
  height: 28px;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  transform: translate(0, 0);

  ${(props) =>
    !props.noPadding &&
    css`
      padding: 0 ${H_PADDING_MOBILE};

      ${mq.gtmd`
      width: ${(5 / 12) * 100}%;
      margin: 0 auto;
      padding: 0;
      `}

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    `}

  ${(props) => props.noPadding && css`
    ${TextWrapper}:last-child {
      margin-bottom: 0;
    }
  `}
`;
