import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

export const Download = styled.a`
  display: block;
  padding: 16px 0;
  color: ${colors.B500};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  text-decoration: underline;
  border-bottom: 1px solid ${colors.B100};
`;

export const Wrapper = styled.div``;
