import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import get from 'lodash/get';

import getLocalizedData, { useIntl } from '@utils/localize';
import ImagePreview from '@components/storyPreview/Image';
import QuotePreview from '@components/storyPreview/Quote';
import TextPreview from '@components/storyPreview/Text';
import Heading from '../Heading';
import * as styled from './styles';

function Stories({ category, heading, stories: selectedStories, type }) {
  const rawData = useStaticQuery(graphql`
    query StoriesSidebarQuery {
      allSanityStory(sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            _id
            category {
              title {
                en
                vi
                zh_Hans
                _type
              }
            }
            featuredQuote {
              en
              vi
              zh_Hans
              _type
            }
            excerpt {
              en
              vi
              zh_Hans
              _type
            }
            heroImage {
              asset {
                fluid(maxWidth: 1200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            publishedAt
            preview
            title {
              en
              vi
              zh_Hans
              _type
            }
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);

  const dynamicStories = data.allSanityStory.edges
    .filter(
      ({ node }) => {
        if (get(category, 'title')) {
          return get(node, 'category.title') === category.title;
        }

        return true;
      },
    )
    .map(({ node }) => node);

  const stories = (type === 'select' ? selectedStories : dynamicStories).slice(0, 3);
  const selectCategory = (categoryName) => {
    navigate(`/stories?category.title[]=${categoryName}`);
  };
  const getStoryPreview = (story) => {
    switch (story.preview) {
      case 'image':
        return <ImagePreview {...story} {...{selectCategory}} type="sidebar" />;
      case 'quote':
        return <QuotePreview {...story} {...{selectCategory}} type="sidebar" />;
      case 'text':
        return <TextPreview {...story} {...{selectCategory}} type="sidebar" />;
      // no default
    }
  };

  return (
    <styled.Wrapper>
      <Heading>{heading}</Heading>
      {stories.map((story, idx) => (
        <styled.Story key={idx}>
          <ImagePreview {...story} {...{selectCategory}} type="sidebar" />
        </styled.Story>
      ))}
    </styled.Wrapper>
  );
}

Stories.propTypes = {
  heading: PropTypes.string.isRequired,
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['text', 'image', 'quote']),
    }),
  ),
};

export default Stories;
