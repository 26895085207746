import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'swiper/js/swiper.esm';
import get from 'lodash/get';

import getCTAProps from '@utils/cta';
import { breakpoints } from '@utils/responsive';
import Button from '@components/Button';
import NavButton from '@components/NavButton';
import Swiper from '@components/Swiper';
import * as styled from './styles';

function SlideImage({ image, altText }) {
  image.asset.fluid.sizes = "(max-width: 600px) 100vw, 50vw";

  return (
    <styled.ImageWrapper>
      <styled.Image fluid={get(image, 'asset.fluid')} ratio={1 / 2} altText={altText}/>
      <styled.Edge />
    </styled.ImageWrapper>
  );
}

function CourseCarousel({
  button,
  heading,
  items,
  colorTheme,
}) {
  const params = {
    loop: true,
    modules: [Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderNextButton: () => (
      <styled.Next className="swiper-button-next" colorTheme={colorTheme}>
        <NavButton direction="next" sizes={['regular', 'large']} colorTheme={colorTheme} />
      </styled.Next>
    ),
    renderPrevButton: () => (
      <styled.Prev className="swiper-button-prev" colorTheme={colorTheme}>
        <NavButton direction="prev" sizes={['regular', 'large']} colorTheme={colorTheme} />
      </styled.Prev>
    ),
    centeredSlides: true,
    slidesPerView: 1.15,
    spaceBetween: 8,
    breakpoints: {
      [breakpoints.tablet]: {
        slidesPerView: 1.75,
        spaceBetween: 32,
      },
    },
  };

  return (
    <styled.Wrapper className="block--course-carousel">
      {heading && (
        <styled.CourseHeading level={100} colorTheme={colorTheme}>
          {heading}
        </styled.CourseHeading>
      )}
      <styled.GlobalStyle />
      <Swiper params={params}>
        {items.map((item) => (
          <styled.Item key={item._key}>
            <SlideImage image={get(item, 'image')} altText={get(item, 'imageExtended.altText')} />
            {item.eyebrow && (
              <styled.Eyebrow sizes={['small', 'regular']}>
                {item.eyebrow}
              </styled.Eyebrow>
            )}
            <styled.Content>
              <styled.Heading levels={[400, 200]}>{item.heading}</styled.Heading>
              {item.description && (
                <styled.Description sizes={['small', 'regular']}>
                  {item.description}
                </styled.Description>
              )}
            </styled.Content>
          </styled.Item>
        ))}
      </Swiper>
      {get(button, 'label') && (
        <styled.ButtonWrapper>
          <Button
            appearance={colorTheme === 'yellow' ? 'blue' : colorTheme}
            fill
            {...getCTAProps(button)}
          >
            {button.label}
          </Button>
        </styled.ButtonWrapper>
      )}
    </styled.Wrapper>
  );
}

CourseCarousel.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  heading: PropTypes.string,
  items: PropTypes.array.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

CourseCarousel.defaultProps = {
  colorTheme: 'purple',
};

export default CourseCarousel;
