import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'swiper/js/swiper.esm';
import { useMediaQuery } from 'react-responsive';
import get from 'lodash/get';

import getCTAProps from '@utils/cta';
import { breakpoints } from '@utils/responsive';
import Button from '@components/Button';
import NavButton from '@components/NavButton';
import Swiper from '@components/Swiper';
import { RoughOne, RoughThree } from '@components/vector';
import Polaroid from './cards/Polaroid';
import Quote from './cards/Quote';
import Value from './cards/Value';
import * as styled from './styles';

const rotate = (arr = [], step) => {
  const n = step % arr.length;
  return [...arr.slice(n, arr.length), ...arr.slice(0, n)];
};

function ContentCarousel(props) {
  const { button, description, heading, items, colorTheme, contentType } = props;
  const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });

  const params = {
    loop: true,
    modules: [Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
    },
    renderNextButton: () => (
      <styled.Next className="swiper-button-next" colorTheme={colorTheme}>
        <NavButton direction="next" sizes={['regular', 'large']} colorTheme={colorTheme} />
      </styled.Next>
    ),
    slidesPerView: 1.5,
    spaceBetween: 32,
    breakpoints: {
      [breakpoints.mobile]: {
        slidesPerView: 2,
      },
      [breakpoints.tablet]: {
        slidesPerView: 3,
      },
    },
  };

  const getChild = (props, idx) => {
    switch (contentType) {
      case 'polaroid':
        return <Polaroid {...props} />;
      case 'quote':
        return <Quote {...props} colorTheme={colorTheme} />;
      case 'value':
        return <Value {...props} number={isMobile ? idx + 1 : (idx || items.length)} />;
      // no default
    }
  };

  return (
    <styled.Wrapper className="block--content-carousel" colorTheme={colorTheme}>
      <RoughThree />
      <styled.Body colorTheme={colorTheme}>
        <styled.Info hasButton={get(button, 'link')} colorTheme={colorTheme}>
          <styled.Heading level={100} colorTheme={colorTheme}>{heading}</styled.Heading>
          {description && (
            <styled.Description sizes={['small', 'regular']}>
              {description}
            </styled.Description>
          )}
          {get(button, 'label') && (
            <styled.ButtonWrapper>
              <Button
                appearance={colorTheme === 'yellow' ? 'blue' : colorTheme}
                {...getCTAProps(button)}
              >
                {button.label}
              </Button>
            </styled.ButtonWrapper>
          )}
        </styled.Info>
        <styled.GlobalStyle />
        <Swiper params={params}>
          {rotate(props[contentType], isMobile ? 0 : -1).map((item, idx) => (
            <styled.Item aria-disabled="true" className={`card--${contentType}`} key={item._key}>{getChild(item, idx)}</styled.Item>
          ))}
        </Swiper>
      </styled.Body>
      <RoughOne />
    </styled.Wrapper>
  );
}

ContentCarousel.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  description: PropTypes.string,
  heading: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
  contentType: PropTypes.oneOf(['polaroid', 'quote', 'value']).isRequired,
};

ContentCarousel.defaultProps = {
  colorTheme: 'purple',
};

export default ContentCarousel;
