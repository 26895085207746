import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { injectIntl } from 'gatsby-plugin-react-intl';
import styled from 'styled-components';

import colors from '@utils/colors';
import Button from '@components/Button';
import Container from '@components/Container';
import { MapPin } from '@components/Icon';
import customStyles from './customStyles.json';

const ButtonWrapper = styled.div`
  margin-top: 32px;
  text-align: center;
`;

const Marker = () => (
  <div>
    <MapPin
      border={colors.B500}
      color={colors.Y500}
      size={36}
      transform="translate(-18, -36)"
    />
  </div>
);

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 42.425884,
      lng: -73.24409,
    },
    zoom: 11,
  };

  render() {
    return (
      <Container centered columns={[12, 6]}>
        <div style={{ height: '60vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            options={{ styles: customStyles }}
          >
            <Marker lat={42.425884} lng={-73.24409} />
          </GoogleMapReact>
        </div>
        <ButtonWrapper>
          <Button
            appearance="blue"
            fill
            href="https://goo.gl/maps/oHrzzPDzmH3FxLfM6"
            target="_blank"
            rel="noopener noreferrer"
          >
            {this.props.intl.formatMessage({ id: 'getDirections' })}
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }
}

export default injectIntl(Map);
