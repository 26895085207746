import { Link as UnstyledLink } from 'gatsby-plugin-react-intl';
import styled from 'styled-components';
import { mq } from '@utils/responsive';
import colors from '@utils/colors';
import { Body } from '@components/type';
import fonts, { weights } from '@utils/fonts';

export const Description = styled(Body)`
  margin-top: 10px;
`;

export const ExternalLink = styled.a`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 12px;
  font-weight: ${weights.BOLD};
  text-decoration: underline;
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  ${mq.gtsm`
    width: 300px;
    height: 300px;
    max-width: 300px;
  `}
`;

export const Link = styled(UnstyledLink)`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 12px;
  font-weight: ${weights.BOLD};
  text-decoration: underline;
`;

export const Wrapper = styled.div`
  align-items: center;
  padding: 16px 0;
  border-top: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        text: colors.B500,
      };
  }
}
