import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import format from 'date-fns/format';
import get from 'lodash/get';
import { useIntl } from '@utils/localize';

import { dateLocales } from '@utils/localize';
import { Body, Caption, Heading as UnstyledHeading } from '@components/type';
import Heading from '../Heading';
import * as styled from './styles';

function Events({ events, heading, colorTheme }) {
  const intl = useIntl();

  return (
    <styled.Wrapper>
      <Heading colorTheme={colorTheme}>{heading}</Heading>
      {events.map((event, idx) => (
        <styled.Event key={idx}>
          {event.startTime && (
            <styled.EventDate>
              <UnstyledHeading level={400}>
                {format(new Date(event.startTime), 'd', {
                  locale: dateLocales[intl.locale],
                })}
              </UnstyledHeading>
              <Body size="small">
                {format(new Date(event.startTime), 'LLL', {
                  locale: dateLocales[intl.locale],
                })}
              </Body>
            </styled.EventDate>
          )}
          <div>
            <Link to={`/calendar/${get(event.slug, 'current')}`} aria-label={event.title}>
              <UnstyledHeading level={500}>
                {event.title}
              </UnstyledHeading>
            </Link>
          </div>
        </styled.Event>
      ))}
    </styled.Wrapper>
  );
}

Events.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default Events;
