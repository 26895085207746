import React, { useEffect, useState } from 'react';
import { useIntl } from '@utils/localize';

import Button from '@components/Button';
import { Search } from '@components/Icon';
import { Heading } from '@components/type';
import Typewriter from '../Typewriter';
import * as styled from './styles';

const SearchForm = React.forwardRef(
  ({ placeholders, onSave, onSearch, search, showSave }, ref) => {
    const intl = useIntl();

    const [query, setQuery] = useState('');
    const [showTypewriter, setShowTypewriter] = useState(true);

    const handleBlur = () => {
      setShowTypewriter(true);
    };

    const handleFocus = () => {
      setShowTypewriter(false);
    };

    const handleChange = (evt) => {
      setQuery(evt.target.value);
    };

    const handleSubmit = (evt) => {
      evt.preventDefault();

      onSearch(query);
    };

    useEffect(() => {
      if (search === '') {
        setQuery('');
      }
    }, [search]);

    return (
      <styled.Form onSubmit={handleSubmit}>
        <Heading level={300}>{intl.formatMessage({ id: 'horizonPicker.searchForm.heading' })}</Heading>
        <styled.InputWrapper>
          <styled.Input
            aria-label="Search Horizons"
            value={query}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={ref}
          />
          {showTypewriter && !query && <Typewriter strings={placeholders} />}
        </styled.InputWrapper>
        <styled.Actions>
          <Button appearance="green" fill isIconOnly type="submit" ariaLabel="search">
            <Search />
          </Button>
          {showSave && (
            <Button appearance="green" fill onClick={onSave} isDisabled={!!query.length} type="button">
              {intl.formatMessage({ id: 'horizonPicker.searchForm.saveButton' })}
            </Button>
          )}
        </styled.Actions>
      </styled.Form>
    );
  },
);

export default SearchForm;
