import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Heading from '../Heading';
import * as styled from './styles';

function Downloads({ downloads, heading }) {
  return (
    <styled.Wrapper>
      <Heading>{heading}</Heading>
      {downloads && downloads.length && downloads.map((download, idx) => (
        <styled.Download key={idx} href={get(download, 'link.asset.url')} download>
          {download.label}
        </styled.Download>
      ))}
    </styled.Wrapper>
  );
}

Downloads.propTypes = {
  downloads: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
  })),
  heading: PropTypes.string.isRequired,
};

export default Downloads;
