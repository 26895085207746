import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Image from '@components/Image';
import { Heading } from '@components/type';
import * as styled from './styles';

function Person({ email, heading, image, name, colorTheme, title, type }) {
  if (image && image.asset && image.asset.fluid && image.asset.fluid.sizes) {
    image.asset.fluid.sizes = '10vw';
  }

  return (
    <styled.Wrapper colorTheme={colorTheme}>
      {get(image, 'asset.fluid') && (
        <styled.ImageWrapper>
          <Image edge={0} fluid={image.asset.fluid} />
        </styled.ImageWrapper>
      )}
      <div>
        <Heading level={500}>{type === 'contact' ? name : heading}</Heading>
        {title && type === 'contact' && <styled.Title>{title}</styled.Title>}
        <styled.Email href={`mailto:${email}`} colorTheme={colorTheme}>
          Email me
        </styled.Email>
      </div>
    </styled.Wrapper>
  );
}

Person.propTypes = {
  email: PropTypes.string.isRequired,
  heading: PropTypes.string,
  image: PropTypes.object,
  name: PropTypes.string,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
  title: PropTypes.string,
  type: PropTypes.oneOf(['contact', 'question']),
};

Person.defaultProps = {
  colorTheme: 'blue',
  type: 'contact',
};

export default Person;
