import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import getCTAProps from '@utils/cta';
import getLocalizedData from '@utils/localize';
import Button from '@components/Button';
import { RoughOne, RoughThree } from '@components/vector';
import Image from '@components/storyPreview/Image';
import Quote from '@components/storyPreview/Quote';
import Text from '@components/storyPreview/Text';
import * as styled from './styles';
import { ThemeProvider } from 'styled-components';

function Stories({
  button,
  category,
  heading,
  headingAlignment,
  maxStories,
  colorTheme,
}) {
  const rawData = useStaticQuery(graphql`
    query StoriesBlockQuery {
      allSanityStory(sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            _id
            category {
              title {
                en
                vi
                zh_Hans
                _type
              }
            }
            featuredQuote {
              en
              vi
              zh_Hans
              _type
            }
            excerpt {
              en
              vi
              zh_Hans
              _type
            }
            heroImageExtended {
              credit
              link
              route {
                title {
                  _type
                  en
                  vi
                  zh_Hans
                }
                slug {
                  current
                }
              }
              altText {
                en
                vi
                zh_Hans
                _type
              }
              image  {
                asset {
                  fluid(
                    maxWidth: 800,
                    sizes: "(max-width: 600px) 100vw, 50vw"
                    ){
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            publishedAt
            preview
            title {
              en
              vi
              zh_Hans
              _type
            }
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const data = getLocalizedData(rawData, intl.locale);

  const stories = data.allSanityStory.edges
    .filter(
      ({ node }) => {
        if (get(category, 'title')) {
          return get(node, 'category.title') === category.title;
        }

        return true;
      },
    )
    .map(({ node }) => node);

  const getStoryCard = (story, idx) => {
    switch (story.preview) {
      case 'image':
        return <Image key={idx} {...story} colorTheme={colorTheme} type="card" />;
      case 'quote':
        return (
          <Quote
            key={idx}
            {...story}
            colorTheme={colorTheme}
            size={maxStories === '4' ? 'small' : 'large'}
            type="card"
          />
        );
      case 'text':
        return <Text key={idx} {...story} colorTheme={colorTheme} type="card" />;
      // no default
    }
  };

  const getStories = () => {
    if (maxStories === '4') {
      return stories.slice(0, 4);
    }

    return stories.slice(0, 3);
  };

  return (
    <ThemeProvider theme={{locale: 'en'}}>
    <styled.Wrapper>
      <styled.Header colorTheme={colorTheme}>
        <styled.Heading
          align={headingAlignment}
          caps={maxStories === '4'}
          levels={[100, maxStories === '4' ? 200 : 100]}
          colorTheme={colorTheme === 'yellow' ? 'blue' : colorTheme}
        >
          {heading}
        </styled.Heading>
        <RoughThree />
      </styled.Header>
      <styled.Body size={maxStories === '4' ? 'small' : 'large'} colorTheme={colorTheme}>
        {getStories().map(getStoryCard)}
        <styled.Spacer />
      </styled.Body>
      <styled.Footer colorTheme={colorTheme}>
        <Button
          appearance={colorTheme === 'yellow' ? 'blue' : colorTheme}
          {...getCTAProps(button, {
            query: category ? `?category.title[]=${category.title}` : '',
          })}
        >
          {button.label}
        </Button>
        <RoughOne />
      </styled.Footer>
    </styled.Wrapper>
    </ThemeProvider>
  );
}

Stories.propTypes = {
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string.isRequired,
  headingAlignment: PropTypes.oneOf(['right', 'left']),
  size: PropTypes.oneOf(['small', 'large']),
  stories: PropTypes.array.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

Stories.defaultProps = {
  headingAlignment: 'left',
  size: 'small',
  colorTheme: 'yellow',
};

export default Stories;
