import React, { forwardRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecaptcha } from 'react-hook-recaptcha';
import get from 'lodash/get';
import numeral from 'numeral';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInMonths from 'date-fns/differenceInMonths';
import { useIntl } from '@utils/localize';

import 'react-datepicker/dist/react-datepicker.css';
import colors from '@utils/colors';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import Container from '@components/Container';
import {
  Field,
  FormError,
  Input,
  Label,
  Textarea,
} from '@components/Contact/styles';
import { ChevronDown } from '@components/Icon';
import Space from '@components/Space';
import { Body, Heading } from '@components/type';
import * as styled from './styles';
import regions from './regions';

const sitekey = process.env.GATSBY_RECAPTCHA_PUBLIC_KEY;
const containerId = 'test-recaptcha'; // this id can be customized

const DonationForm = forwardRef((props, ref) => {
  const intl = useIntl();

  const [giftType, setGiftType] = useState('gift');
  const [recurringPeriod, setRecurringPeriod] = useState();
  const [isRecurring, setRecurring] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const monthlyPeriods = differenceInMonths(endDate, startDate) + 1;
  const yearlyPeriods = differenceInYears(endDate, startDate) + 1;

  const {
    control,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      country: intl.formatMessage({ id: 'donation.defaultCountry' }),
    },
  });

  const [captchaResponse, setCaptchaResponse] = useState(null);
  const expiredCallback = () => setCaptchaResponse(null);
  const successCallback = (response) => {
    setCaptchaResponse(response);
  };
  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    sitekey,
    size: 'normal',
  });
  const executeCaptcha = (e) => {
    e.preventDefault();
    return handleSubmit((values) => _handleSubmit({...values, captchaResponse}))();
  }

  const {
    country,
    annualFund,
    myReunionGift,
    studentFund,
    givingTuesday,
    other,
  } = watch([
    'country',
    'annualFund',
    'myReunionGift',
    'studentFund',
    'givingTuesday',
    'other',
  ]);

  const recurringOptions = {
    1: '1 year',
    2: '2 years',
    3: '3 years',
    100: 'Indefinitely'
  }
  const fieldTotals =
    parseInt((annualFund || '0').replace(',', ''), 10) +
    parseInt((myReunionGift || '0').replace(',', ''), 10) +
    parseInt((studentFund || '0').replace(',', ''), 10) +
    parseInt((other || '0').replace(',', ''), 10);

  const donationTotal =
    isNaN(fieldTotals) || !fieldTotals
      ? ''
      : numeral(fieldTotals).format('0,0');

  let periods = 1;
  if (recurringPeriod === 'monthly' && monthlyPeriods > 0) {
    periods = monthlyPeriods;
  } else if (recurringPeriod === 'yearly' && yearlyPeriods > 0) {
    periods = yearlyPeriods;
  }

  const recurringTotal =
    parseInt((donationTotal || '0').replace(',', ''), 10) * periods;

  const _handleSubmit = (values) => {
    const amount =
      parseInt((values.annualFund || '0').replace(',', ''), 10) +
      parseInt((values.myReunionGift || '0').replace(',', ''), 10) +
      parseInt((values.studentFund || '0').replace(',', ''), 10) +
      parseInt((values.other || '0').replace(',', ''), 10);

    props.onSubmit({
      ...values,
      giftType,
      isRecurring,
      recurringPeriod,
      startDate,
      endDate,
      amount,
      payments: periods,
    });
  };

  return (
    <styled.Wrapper>
      <styled.CalendarStyles />
      <Container centered columns={[12, 6]}>
        {props.heading && (
          <>
            <Heading levels={[400, 200]}>{props.heading}</Heading>
            {props.description && <Body>{props.description}</Body>}
          </>
        )}
        <Space size="xsmall" />
        <styled.FormWrapper>
          {props.transactionId ? (
            <styled.Success ref={ref}>
              <styled.Heading levels={[400, 200]}>
                {intl.formatMessage({ id: 'donation.success.heading' })}
              </styled.Heading>
              <Body>
                {intl.formatMessage({ id: 'donation.success.body' })}{' '}
                {props.transactionId}
              </Body>
              <Button
                appearance="blue"
                fill
                onClick={() => {
                  reset();
                  props.onClose();
                }}
              >
                {intl.formatMessage({ id: 'donation.success.button' })}
              </Button>
            </styled.Success>
          ) : (
            <form onSubmit={executeCaptcha}>
              <styled.Section>
                <styled.Heading level={400}>
                  {intl.formatMessage({
                    id: 'donation.form.section.donorDetails',
                  })}
                </styled.Heading>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.firstName') && errors.firstName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.firstName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.firstName') &&
                          errors.firstName
                        }
                        name="firstName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.firstName') && errors.firstName && (
                      <FormError>
                        {intl.formatMessage({
                          id: 'donation.form.error.firstName',
                        })}
                      </FormError>
                    )}
                  </Field>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.lastName') && errors.lastName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.lastName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.lastName') && errors.lastName
                        }
                        name="lastName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.lastName') && errors.lastName && (
                      <FormError>
                        {intl.formatMessage({
                          id: 'donation.form.error.lastName',
                        })}
                      </FormError>
                    )}
                  </Field>
                </styled.Fieldset>
                <Field>
                  <Label>
                    {intl.formatMessage({
                      id: 'donation.form.label.maidenName',
                    })}
                    <Input
                      color={colors.B100}
                      name="maidenName"
                      ref={register}
                    />
                  </Label>
                </Field>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={get(formState, 'touched.email') && errors.email}
                    >
                      {intl.formatMessage({ id: 'donation.form.label.email' })}*
                      <Input
                        color={colors.B100}
                        error={get(formState, 'touched.email') && errors.email}
                        name="email"
                        type="email"
                        ref={register({
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.email') && errors.email && (
                      <FormError>
                        {intl.formatMessage({
                          id: 'donation.form.error.email',
                        })}
                      </FormError>
                    )}
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.phone' })}
                      <Controller
                        as={Input}
                        color={colors.B100}
                        control={control}
                        name="phone"
                        type="tel"
                      />
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.address1',
                      })}
                      <Input
                        color={colors.B100}
                        name="address1"
                        ref={register}
                      />
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.address2',
                      })}
                      <Input
                        color={colors.B100}
                        name="address2"
                        ref={register}
                      />
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.city' })}
                      <Input color={colors.B100} name="city" ref={register} />
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.state' })}
                      {country === 'US' || country === 'CA' ? (
                        <styled.SelectWrapper>
                          <styled.Select name="region" ref={register}>
                            <option value=""></option>
                            {regions.territories[country].map((territory) => (
                              <option
                                key={territory.value}
                                value={territory.value}
                              >
                                {territory.label}
                              </option>
                            ))}
                          </styled.Select>
                          <ChevronDown />
                        </styled.SelectWrapper>
                      ) : (
                        <Input
                          color={colors.B100}
                          name="region"
                          ref={register}
                        />
                      )}
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset size={[1, 2]}>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.zip' })}
                      <Input
                        color={colors.B100}
                        error={get(formState, 'touched.zip') && errors.zip}
                        name="zip"
                        ref={register}
                      />
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.country',
                      })}
                      <styled.SelectWrapper>
                        <styled.Select name="country" ref={register}>
                          <option value=""></option>
                          {regions.countries.map((country) => (
                            <option key={country.value} value={country.value}>
                              {country.label}
                            </option>
                          ))}
                        </styled.Select>
                        <ChevronDown />
                      </styled.SelectWrapper>
                    </Label>
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.affiliation') &&
                        errors.affiliation
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.affiliation',
                      })}
                      *
                      <styled.SelectWrapper>
                        <styled.Select
                          name="affiliation"
                          ref={register({ required: true })}
                        >
                          <option value="Alumna">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.alumna',
                            })}
                          </option>
                          <option value="Current Parent">
                            {intl.formatMessage({
                              id:
                                'donation.form.field.affiliation.currentParent',
                            })}
                          </option>
                          <option value="Parent of Alumna">
                            {intl.formatMessage({
                              id:
                                'donation.form.field.affiliation.parentOfAlumna',
                            })}
                          </option>
                          <option value="Grandparent">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.grandparent',
                            })}
                          </option>
                          <option value="Faculty">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.faculty',
                            })}
                          </option>
                          <option value="Friend">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.friend',
                            })}
                          </option>
                          <option value="Staff">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.staff',
                            })}
                          </option>
                          <option value="Other">
                            {intl.formatMessage({
                              id: 'donation.form.field.affiliation.other',
                            })}
                          </option>
                        </styled.Select>
                        <ChevronDown />
                      </styled.SelectWrapper>
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.classYear',
                      })}
                      <Input
                        color={colors.B100}
                        name="classYear"
                        maxLength={4}
                        ref={register}
                      />
                    </Label>
                  </Field>
                </styled.Fieldset>
              </styled.Section>
              <styled.Heading level={400}>
                {intl.formatMessage({ id: 'donation.form.section.giftDetails' })}
              </styled.Heading>
              <styled.LabeledSection>
                <styled.Heading level={500}>
                  {intl.formatMessage({
                    id: 'donation.form.section.label.giftAmount',
                  })}
                </styled.Heading>
                <styled.Section>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.annualFund',
                      })}
                      <styled.InputWrapper>
                        <styled.DollarSign>$</styled.DollarSign>
                        <Controller
                          as={<Input color={colors.B100} />}
                          name="annualFund"
                          onChange={([e]) =>
                            numeral(e.target.value).format('0,0')
                          }
                          control={control}
                        />
                      </styled.InputWrapper>
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.myReunionGift',
                      })}
                      <styled.InputWrapper>
                        <styled.DollarSign>$</styled.DollarSign>
                        <Controller
                          as={<Input color={colors.B100} />}
                          name="myReunionGift"
                          onChange={([e]) =>
                            numeral(e.target.value).format('0,0')
                          }
                          control={control}
                        />
                      </styled.InputWrapper>
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.studentFund',
                      })}
                      <styled.InputWrapper>
                        <styled.DollarSign>$</styled.DollarSign>
                        <Controller
                          as={<Input color={colors.B100} />}
                          name="studentFund"
                          onChange={([e]) =>
                            numeral(e.target.value).format('0,0')
                          }
                          control={control}
                        />
                      </styled.InputWrapper>
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.other' })}
                      <styled.InputWrapper>
                        <styled.DollarSign>$</styled.DollarSign>
                        <Controller
                          as={<Input color={colors.B100} />}
                          name="other"
                          onChange={([e]) =>
                            numeral(e.target.value).format('0,0')
                          }
                          control={control}
                        />
                      </styled.InputWrapper>
                    </Label>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.total' })}
                      <styled.InputWrapper locked>
                        <styled.DollarSign>$</styled.DollarSign>
                        <Input
                          color={colors.B100}
                          disabled
                          value={donationTotal}
                        />
                      </styled.InputWrapper>
                    </Label>
                  </Field>

                <Field>
                  <styled.Fieldset>
                    <styled.Radio
                      checked={isRecurring}
                      onClick={() => {
                        setRecurring(!isRecurring);
                      }}
                    >
                      <Checkbox
                        checked={isRecurring}
                        label={intl.formatMessage({
                          id: 'donation.form.label.makeRecurring',
                        })}
                        onChange={() => {
                          setRecurring(!isRecurring);
                        }}
                        round
                      />
                    </styled.Radio>
                  </styled.Fieldset>
                </Field>

              {isRecurring && (
              <>
                <Field>
                  <Label>
                    {intl.formatMessage({
                      id: 'donation.form.label.frequency',
                    })}
                  </Label>
                  <styled.Fieldset>
                    <styled.Radio
                      checked={recurringPeriod === 'monthly'}
                      onClick={() => {
                        setRecurringPeriod('monthly');
                      }}
                    >
                      <Checkbox
                        checked={recurringPeriod === 'monthly'}
                        label={intl.formatMessage({
                          id: 'donation.form.field.frequency.monthly',
                        })}
                        onChange={() => {
                          setRecurringPeriod('monthly');
                        }}
                        round
                      />
                    </styled.Radio>
                    <styled.Radio
                      checked={recurringPeriod === 'yearly'}
                      onClick={() => {
                        setRecurringPeriod('yearly');
                      }}
                    >
                      <Checkbox
                        checked={recurringPeriod === 'yearly'}
                        label={intl.formatMessage({
                          id: 'donation.form.field.frequency.annually',
                        })}
                        onChange={() => {
                          setRecurringPeriod('yearly');
                        }}
                        round
                      />
                    </styled.Radio>
                  </styled.Fieldset>
                </Field>

                <styled.Fieldset>
                  <Field>
                    <Label>
                      {intl.formatMessage({ id: 'donation.form.label.makeRecurringDropdownLabel' })}*
                        <styled.SelectWrapper>
                          <styled.Select name="recurringPaymentsNumber"
                                         ref={register({
                                           required: true,
                                         })}
                          >
                            <option value=""></option>
                            {Object.keys(recurringOptions).map((key) => (
                              <option
                                key={key}
                                value={key}
                              >
                                {recurringOptions[key]}
                              </option>
                            ))}
                          </styled.Select>
                          <ChevronDown />
                        </styled.SelectWrapper>
                    </Label>
                  </Field>
                </styled.Fieldset>
              </>
              )}
                </styled.Section>
              </styled.LabeledSection>
              <styled.LabeledSection>
                <styled.Heading level={500}>
                  {intl.formatMessage({
                    id: 'donation.form.section.label.details',
                  })}
                </styled.Heading>
                <styled.Section>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.giftMatched',
                      })}
                      <Input
                        color={colors.B100}
                        name="giftMatched"
                        ref={register}
                      />
                    </Label>
                    <styled.Description>
                      {intl.formatMessage({ id: 'donation.form.label.mailTo' })}{' '}
                      Advancement Office
                      <br />
                      Miss Hall's School
                      <br />
                      492 Holmes Road
                      <br />
                      Pittsfield, MA 01202
                    </styled.Description>
                  </Field>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.comments',
                      })}
                      <Textarea
                        color={colors.B100}
                        name="comments"
                        placeholder={intl.formatMessage({ id: 'donation.form.field.comments.placeholder' })}
                        ref={register}
                      />
                    </Label>
                  </Field>
                </styled.Section>
              </styled.LabeledSection>
              <Space size="xsmall" />
              <styled.Section>
                <styled.Heading level={400}>
                  {intl.formatMessage({
                    id: 'donation.form.section.paymentInformation',
                  })}
                </styled.Heading>
                <styled.Fieldset>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.ccFirstName') &&
                        errors.ccFirstName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.firstName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.ccFirstName') &&
                          errors.ccFirstName
                        }
                        name="ccFirstName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.ccFirstName') &&
                      errors.ccFirstName && (
                        <FormError>
                          {intl.formatMessage({
                            id: 'donation.form.error.firstName',
                          })}
                        </FormError>
                      )}
                  </Field>
                  <Field>
                    <Label
                      error={
                        get(formState, 'touched.ccLastName') &&
                        errors.ccLastName
                      }
                    >
                      {intl.formatMessage({
                        id: 'donation.form.label.lastName',
                      })}
                      *
                      <Input
                        color={colors.B100}
                        error={
                          get(formState, 'touched.ccLastName') &&
                          errors.ccLastName
                        }
                        name="ccLastName"
                        ref={register({
                          required: true,
                        })}
                      />
                    </Label>
                    {get(formState, 'touched.ccLastName') &&
                      errors.ccLastName && (
                        <FormError>
                          {intl.formatMessage({
                            id: 'donation.form.error.lastName',
                          })}
                        </FormError>
                      )}
                  </Field>
                </styled.Fieldset>
                <styled.Fieldset>
                  <Field>
                    <Label>
                      {intl.formatMessage({
                        id: 'donation.form.label.cardNumber',
                      })}
                      *
                      <div id="ccnumber" />
                    </Label>
                  </Field>
                  <styled.Fieldset>
                    <Field>
                      <Label>
                        {intl.formatMessage({
                          id: 'donation.form.label.cardExpiration',
                        })}
                        *
                        <div id="ccexp" />
                      </Label>
                    </Field>
                    <Field>
                      <Label>
                        {intl.formatMessage({
                          id: 'donation.form.label.cardSecurityCode',
                        })}
                        *
                        <div id="cvv" />
                      </Label>
                    </Field>
                  </styled.Fieldset>
                </styled.Fieldset>
              </styled.Section>
              <div id={containerId} />
              {props.error && <FormError>{props.error}</FormError>}
              <styled.Submit>
                <Button
                  fill
                  isDisabled={
                    captchaResponse === null ||
                    props.loading ||
                    parseInt((donationTotal || '0').replace(',', ''), 10) <= 0
                  }
                  type="submit"
                >
                  {props.loading
                    ? intl.formatMessage({ id: 'donation.form.submit.loading' })
                    : intl.formatMessage({
                        id: 'donation.form.submit.default',
                      })}
                </Button>
              </styled.Submit>
            </form>
          )}
        </styled.FormWrapper>
      </Container>
    </styled.Wrapper>
  );
});

export default DonationForm;
