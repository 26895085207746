import styled from 'styled-components';
import { motion } from 'framer-motion';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { Heading as UnstyledHeading } from '@components/type';

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 24px;
  z-index: 1;
  height: 32px;
  width: 32px;
  margin-right: -8px;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 6px;
  appearance: none;
  outline: 0;
  cursor: pointer;

  & > svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const Form = styled.form`
  margin-top: 24px;

  & > button {
    margin-top: 8px;
  }

  ${mq.gtsm`
    margin-top: 32px;
  `}
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 4px;

  ${mq.gtsm`
    margin-bottom: 8px;
  `}
`;

export const Modal = styled(motion.div)`
  position: relative;
  width: 400px;
  background-color: ${colors.G100};

  & > svg {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    transform: translateY(-2px) scale(1, -1);
  }
`;

export const Inner = styled.div`
  position: relative;
  padding: 32px;
  overflow: hidden;
`;

export const Success = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 48px;
  text-align: center;

  & > button {
    margin-top: 32px;
  }
`;

export const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.6);
`;
