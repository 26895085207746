import React from 'react';

import emailHandler from '@utils/emailHandler';
import ContactBlock from './component';

function ContactContainer(props) {
  const { send } = emailHandler({ templateId: 'd-65adf5ec013a41edb32353ab18888722' });

  const handleSubmit = async (values) => {
    await send({
      sender: 'web@misshalls.org',
      subject: 'New Contact Form Submission',
      recipient: 'recruiting@misshalls.org',
      variables: {
        ...values,
      },
    });
  };

  return (
    <ContactBlock onSubmit={handleSubmit} {...props} />
  );
}

export default ContactContainer;
