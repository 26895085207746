import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import fonts from '@utils/fonts';
import { mq } from '@utils/responsive';
import UnstyledImage from '@components/Image';
import { Caption } from '@components/type';
import getFluidImage from '@utils/fluidImage';

const GlobalStyle = createGlobalStyle`
  .card--polaroid.swiper-slide {
    opacity: 1;
    transform: rotate(-1deg);
    transition: opacity 250ms linear, transform 500ms ease;
  }

  .card--polaroid.swiper-slide-next,
  .card--polaroid.swiper-slide-prev {
    transform: rotate(1deg);
  }

  ${mq.gtsm`
    .card--polaroid.swiper-slide-active,
    .card--polaroid.swiper-slide-prev {
      opacity: 0.1;
    }
  `}
`;

const Image = styled(UnstyledImage)`
  margin-bottom: 8px;

  ${mq.gtsm`
    margin-bottom: 24px;
  `}
`;

const Heading = styled.p`
  font-family: ${props => fonts(props.theme.locale).HANDWRITTEN};
  font-size: 16px;
  line-height: 120%;

  ${mq.gtsm`
    font-size: 28px;
  `}
`;

const Wrapper = styled.div`
  padding: 8px;
  background-color: ${colors.N0};

  ${mq.gtsm`
    padding: 24px;
  `}
`;

function PolaroidCard(props) {
  const { caption, heading, imageExtended } = props;
  const image = {
    ...imageExtended.image.asset,
    fluid: getFluidImage(imageExtended.image, { sizes: '(max-width: 600px) 100vw, 50vw'})
  };

  image.fluid.sizes = "(max-width: 600px) 100vw, 30vw";

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        {get(image, 'fluid') && (
          <Image fluid={image.fluid} draggable={false} loading="eager" altText={imageExtended.altText}/>
        )}
        <Caption sizes={['small', 'regular']}>{caption}</Caption>
        <Heading>{heading}</Heading>
      </Wrapper>
    </>
  );
}

export default PolaroidCard;
