import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { RoughOne, RoughThree } from '@components/vector';
import { Body } from '@components/type';
import * as styled from './styles';

function Testimonial({ image, imageExtended, imageAlignment, name, quote, colorTheme, title, locale }) {
  return (
    <styled.Wrapper hasImage={Boolean(image)}>
      <styled.BackdropWrapper hasImage={Boolean(image)} colorTheme={colorTheme}>
        <RoughThree />
        <styled.Backdrop colorTheme={colorTheme}>
          {!image && (
            <styled.Quote hasImage={false}
                          hasStudent={Boolean(name)}
                          colorTheme={colorTheme}
                          locale={locale}>
              &ldquo;{quote}&rdquo;
            </styled.Quote>
          )}
          {!image && name && (
            <styled.Student
              align={imageAlignment}
              hasImage={false}
            >
              <styled.Details>
                <styled.Name levels={[400, 300]}>{name}</styled.Name>
                {title && (
                  <Body sizes={['small', 'regular']}>{title}</Body>
                )}
              </styled.Details>
            </styled.Student>
          )}
        </styled.Backdrop>
        <RoughOne />
      </styled.BackdropWrapper>
      <styled.Inner>
        {image && (
          <>
            <styled.Quote hasImage={true} colorTheme={colorTheme} locale={locale}>
              &ldquo;{quote}&rdquo;
            </styled.Quote>
            <styled.Student
              align={imageAlignment}
              hasImage={Boolean(get(image, 'asset.fluid'))}
            >
              {get(image, 'asset.fluid') && (
                <styled.Image fluid={image.asset.fluid} edge={0} altText={imageExtended.altText}/>
              )}
              {name && title && (
                <styled.Details>
                  <styled.Name levels={[400, 300]}>{name}</styled.Name>
                  <Body sizes={['small', 'regular']}>{title}</Body>
                </styled.Details>
              )}
            </styled.Student>
          </>
        )}
      </styled.Inner>
    </styled.Wrapper>
  );
}

Testimonial.propTypes = {
  image: PropTypes.object,
  imageAlignment: PropTypes.oneOf(['right', 'left']),
  name: PropTypes.string,
  quote: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
  title: PropTypes.string,
  locale: PropTypes.string,
};

Testimonial.defaultProps = {
  imageAlignment: 'right',
  colorTheme: 'yellow',
};

export default Testimonial;
