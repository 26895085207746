import { useEffect, useState, useCallback } from 'react';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);

function useAlgoliaSearch({
  queries = [],
  initialSearch = '',
  initialResults = [],
  resolveResults = null,
}) {
  const [search, setSearch] = useState(initialSearch);
  const [results, setResults] = useState(initialResults);
  const [loading, setLoading] = useState(false);

  const getResults = useCallback(async () => {
    setLoading(true);
    let res;

    if (search === null || search.length === 0) {
      res = {
        results:  queries.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        }))
      }
    } else {
      res = await searchClient.multipleQueries(
        queries.map((query) => ({ ...query, query: search })),
      );
    }

    setResults(res.results);
    setLoading(false);
  }, [search, queries]);

  useEffect(() => {
    getResults();
  }, [search, getResults]);

  return {
    search,
    setSearch,
    results:
      typeof resolveResults === 'function' ? resolveResults(results) : results,
    isLoading: loading,
  };
}

export default useAlgoliaSearch;
