import React, { useState } from 'react';
import styled from 'styled-components';

import fonts from '@utils/fonts';
import colors from '@utils/colors';
import { mq } from '@utils/responsive';

const Child = styled.span`
  background-image: linear-gradient(#fff 60%, ${colors.Y500} 55%);
`;

const Tooltip = styled.span`
  display: block;
  position: fixed;
  left: 50%;
  width: calc(100vw - 48px);
  z-index: 1;
  padding: 16px;
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: 120%;
  background-color: ${colors.N0};
  border-radius: 8px;
  border: 3px solid ${colors.P100};
  pointer-events: ${(props) => props.open ? 'auto' : 'none'};
  opacity: ${(props) => props.open ? 1 : 0};
  transform: translate(-50%, calc(-100% - 36px));
  transition: opacity 150ms linear, transform 150ms ease;

  ${mq.gtmd`
    position: absolute;
    left: 50%;
    right: auto;
    bottom: 32px;
    width: 360px;
    transform: ${(props) => props.open ? 'translate(-50%, -6px)' : 'translate(-50%, 0)'};

    &::after,
    &::before {
      display: inline-block;
      position: absolute;
      bottom: -38px;
      left: 50%;
      content: '';
      height: 0;
      width: 0;
      border: solid transparent;
      pointer-events: none;
      transform: translateX(-50%);
    }

    &::after {
      bottom: -30px;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 15px;
    }

    &::before {
      border-color: rgba(245, 240, 246, 0);
      border-top-color: ${colors.P100};
      border-width: 19px;
    }
  `}
`;

const Wrapper = styled.span`
  position: relative;
  cursor: pointer;
`;

function Annotation({ annotation, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenTooltip = () => {
    setIsOpen(true);
  };

  const handleCloseTooltip = () => {
    setIsOpen(false);
  };

  return (
    <Wrapper onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
      <Child>{children}</Child>
      <Tooltip open={isOpen}>
        {annotation}
      </Tooltip>
    </Wrapper>
  );
}

export default Annotation;
