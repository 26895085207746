import React from 'react';
import styled from 'styled-components';

import Container from '@components/Container';

const Child = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 100%;
`;

function Embed({ content }) {
  return (
    <Container centered>
      <Wrapper>
        <Child dangerouslySetInnerHTML={{ __html: content }} />
      </Wrapper>
    </Container>
  );
}

export default Embed;
