import React from 'react';
import Gallery from 'react-photo-gallery';
import { useMediaQuery } from 'react-responsive';
import { useIntl } from '@utils/localize';
import styled from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import { breakpoints, mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import Button from '@components/Button';
import { RoughOne, RoughThree } from '@components/vector';
import { Body as UnstyledBody, Heading as UnstyledHeading } from '@components/type';

const Body = styled.div`
  position: relative;
  background-color: ${(props) => getThemeStyles(props.colorTheme).background};

  ${mq.gtsm`
    padding: 0 0 24px;
  `}

  ${mq.gtmd`
    display: flex;
    overflow: hidden;
  `}
`;

export const ButtonWrapper = styled.div`
  ${mq.gtmd`
    padding-left: 24px;
  `}

  ${mq.gtml`
    padding-left: 0;
  `}
`;

export const Description = styled(UnstyledBody)`
  margin-bottom: 32px;

  ${mq.gtmd`
    margin-bottom: 40px;
    padding-left: 24px;
  `}

  ${mq.gtml`
    padding-left: 0;
  `}
`;

const GalleryWrapper = styled.div`
  padding: 0 16px;
  transform: translateY(-64px);

  & > div {
    width: 100%;
  }

  ${mq.gtsm`
    transform: translateY(0);
  `}

  ${mq.gtmd`
    display: flex;
    align-items: center;
    flex: 1 0 ${(props) => props.galleryWidth};
    width: ${(props) => props.galleryWidth};
    padding: 0;
    transform: translateX(32px);
  `}

  ${mq.gtml`
    align-items: flex-start;
    max-height: 600px;
  `}

  ${mq.gtlg`
    align-items: center;
    max-height: auto;
  `}
`;

export const Heading = styled(UnstyledHeading)`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  margin-bottom: 8px;

  ${mq.gtmd`
    padding-left: 24px;
  `}

  ${mq.gtml`
    padding-left: 0;
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin: 0 0 32px;
  padding: 48px ${H_PADDING_MOBILE} 0;
  transform: translateY(-64px);

  ${mq.gtsm`
    transform: translateY(0);
  `}

  ${mq.gtmd`
    flex: 1 0 40%;
    width: 40%;
    margin: 0;
    padding: 48px 32px 48px calc((100vw - 1280px) / 2);
  `}
`;

const Wrapper = styled.div`
  position: relative;

  & > svg:first-of-type {
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    vertical-align: bottom;
  }

  & > svg:last-of-type {
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    transform: scale(1, -1) translateY(1px);
    vertical-align: top;
  }
`;

function MediaGalleryPreview({ description, images, slug, colorTheme, title }) {
  const intl = useIntl();

  const isMobile = useMediaQuery({ maxWidth: breakpoints.tablet - 1 });

  let targetRowHeight = 300;
  let galleryWidth = '40vw';

  if (get(images, 'length') === 1) {
    galleryWidth = '40vw';
  } else if (get(images, 'length') <= 3) {
    galleryWidth = '60vw';
  } else if (get(images, 'length') <= 4) {
    galleryWidth = '70vw';
  } else if (get(images, 'length') === 5) {
    targetRowHeight = 400;
    galleryWidth = '40vw';
  } else if (get(images, 'length') >= 8) {
    galleryWidth = '60vw';
  }

  return (
    <Wrapper colorTheme={colorTheme}>
      <RoughOne />
      <Body colorTheme={colorTheme}>
        <Info colorTheme={colorTheme}>
          <Heading level={100} colorTheme={colorTheme}>
            {title}
          </Heading>
          {description && (
            <Description sizes={['small', 'regular']}>
              {description}
            </Description>
          )}
          <ButtonWrapper>
            <Button
              appearance={colorTheme === 'yellow' ? 'blue' : colorTheme}
              href={`/gallery/${get(slug, 'current')}`}
            >
              {intl.formatMessage({ id: 'viewGallery' })}
            </Button>
          </ButtonWrapper>
        </Info>
        <GalleryWrapper galleryWidth={galleryWidth}>
          <Gallery margin={8} photos={images.map(image => ({ alt: '', ...image })).slice(0, isMobile ? 4 : 8)} targetRowHeight={targetRowHeight} />
        </GalleryWrapper>
      </Body>
      <RoughThree />
    </Wrapper>
  );
}

export default MediaGalleryPreview;

function getThemeStyles(colorTheme) {
  switch (colorTheme) {
    case 'blue':
      return {
        background: colors.B100,
        button: colors.B200,
        text: colors.B500,
        transparent: colors.B0,
      };
    case 'green':
      return {
        background: colors.G100,
        button: colors.G200,
        text: colors.G500,
        transparent: colors.G0,
      };
    case 'orange':
      return {
        background: colors.O100,
        button: colors.O200,
        text: colors.O500,
        transparent: colors.O0,
      };
    case 'purple':
      return {
        background: colors.P100,
        button: colors.P200,
        text: colors.P500,
        transparent: colors.P0,
      };
    default:
      return {
        background: colors.Y100,
        button: colors.Y500,
        text: colors.B500,
        transparent: colors.Y0,
      };
  }
}
