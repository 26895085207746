import styled, { createGlobalStyle, css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import {
  Body as UnstyledBody,
  Heading as UnstyledHeading,
} from '@components/type';

export const GlobalStyle = createGlobalStyle`
  .block--content-carousel .swiper-button-next {
    background-image: none;
    width: auto;
    height: auto;
    margin: 0;
  }

  .block--content-carousel .swiper-button-next {
    position: absolute;
    right: 24px;
    z-index: 1;
    ${mq.gtmd`
      right: calc((100vw - 1280px) / 2);
    `}
  }

  .block--content-carousel .swiper-wrapper {
    padding: 24px 0 24px 24px;
  }

  ${mq.gtsm`
    .block--content-carousel .swiper-wrapper {
      padding: 24px 0 24px calc((100vw - 1280px) / 2);
    }
  `}
`;

export const Body = styled.div`
  position: relative;
  background-color: ${(props) => getThemeStyles(props.colorTheme).background};

  ${mq.gtsm`
    padding: 24px 0;
  `}
`;

export const Description = styled(UnstyledBody)`
  margin-bottom: 32px;

  ${mq.gtsm`
    margin-bottom: 40px;
    padding-left: 24px;
  `}

  ${mq.gtml`
    padding-left: 0;
  `}
`;

export const ButtonWrapper = styled.div`
  ${mq.gtsm`
    padding-left: 24px;
  `}

  ${mq.gtml`
    padding-left: 0;
  `}
`;

export const Heading = styled(UnstyledHeading)`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  margin-bottom: 8px;

  ${mq.gtsm`
    padding-left: 24px;
  `}

  ${mq.gtml`
    padding-left: 0;
  `}
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 48px ${H_PADDING_MOBILE} 0;

  ${(props) =>
    props.hasButton &&
    css`
      margin-bottom: -48px;
    `}

  ${mq.gtsm`
    position: absolute;
    top: 24px;
    left: 0;
    bottom: 24px;
    width: 50%;
    margin: 0;
    padding: 0 0 0 calc((100vw - 1280px) / 2);
    background-image: linear-gradient(
      to right,
      ${(props) => getThemeStyles(props.colorTheme).background},
      ${(props) => getThemeStyles(props.colorTheme).transparent}
      );
  `}

  ${mq.gtmd`
    width: ${(4 / 12) * 100}%;
  `}

  ${mq.gtxl`
    width: 50%;
  `}
`;

export const Item = styled.div`
  user-select: none;
  cursor: grab;
`;

export const Next = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: calc((100vw - 1280px) / 2);
  bottom: 0;
  width: 72px;
  height: 72px;
  margin: auto 0;

  ${mq.gtsm`
    display: block;
  `}
`;

export const Wrapper = styled.div`
  position: relative;

  & > svg:first-of-type {
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    vertical-align: bottom;
  }

  & > svg:last-of-type {
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    transform: scale(1, -1) translateY(1px);
    vertical-align: top;
  }
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        button: colors.B200,
        text: colors.B500,
        transparent: colors.B0,
      };
    case 'green':
      return {
        background: colors.G100,
        button: colors.G200,
        text: colors.G500,
        transparent: colors.G0,
      };
    case 'orange':
      return {
        background: colors.O100,
        button: colors.O200,
        text: colors.O500,
        transparent: colors.O0,
      };
    case 'purple':
      return {
        background: colors.P100,
        button: colors.P200,
        text: colors.P500,
        transparent: colors.P0,
      };
    default:
      return {
        background: colors.Y100,
        button: colors.Y500,
        text: colors.B500,
        transparent: colors.Y0,
      };
  }
}
