import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { Body, Heading as UnstyledHeading } from '@components/type';
import { BlobOne } from '@components/vector';

const GlobalStyle = createGlobalStyle`
  .card--value.swiper-slide {
    opacity: 1;
    transition: opacity 250ms linear;
  }

  ${mq.gtsm`
    .card--value.swiper-slide-active,
    .card--value.swiper-slide-prev {
      opacity: 0.1;
    }
  `}
`;

const CardNumber = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  top: -20px;
  width: 40px;
  height: 40px;

  ${mq.gtsm`
    top: -40px;
    width: 80px;
    height: 80px;
  `}

  & > svg {
    position: absolute;
    z-index: 0;
    width: 40px;
    height: 40px;
    fill: ${(props) => getNumberColor(props.theme)};

    ${mq.gtsm`
      width: 80px;
      height: 80px;
    `}
  }

  & > *:last-child {
    position: relative;
    z-index: 1;
  }
`;

const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;

  ${mq.gtsm`
    margin-bottom: 16px;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  margin: 24px 0;
  padding: 32px 16px 16px;
  background-color: ${colors.N0};

  ${mq.gtsm`
    margin: 48px 0;
    padding: 64px 24px 24px;
  `}
`;

function ValueCard({ description, heading, number }) {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <CardNumber>
          <BlobOne />
          <UnstyledHeading levels={[400, 200]}>{number}</UnstyledHeading>
        </CardNumber>
        <Heading levels={[400, 200]}>{heading}</Heading>
        <Body sizes={['small', 'regular']}>{description}</Body>
      </Wrapper>
    </>
  );
}

export default ValueCard;

function getNumberColor(theme) {
  switch (theme) {
    case 'blue':
      return colors.B500;
    case 'green':
      return colors.G500;
    case 'orange':
      return colors.O500;
    case 'purple':
      return colors.P500;
    default:
      return colors.B100;
  }
}
