import React from 'react';
import PropTypes from 'prop-types';
import * as styled from './styles';

function SidebarQuote({ personName, personTitle, content, isLarge, colorTheme }) {
  return (
    <styled.Wrapper colorTheme={colorTheme}>
      <div>
        <styled.Quote {...{colorTheme, isLarge}} >{content}</styled.Quote>
        <styled.PersonContainer>
          <div>
            &mdash; {personName}<br />
            {personTitle}
          </div>
        </styled.PersonContainer>
      </div>
    </styled.Wrapper>
  );
}

SidebarQuote.propTypes = {
  personName: PropTypes.string,
  personTitle: PropTypes.string,
  content: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

SidebarQuote.defaultProps = {
  colorTheme: 'blue',
};

export default SidebarQuote;
