import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import { AnimatePresence } from 'framer-motion';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import emailHandler, { templateIds } from '@utils/emailHandler';
import getLocalizedData from '@utils/localize';
import useAlgoliaSearch from '@utils/useAlgoliaSearch';
import { isExternalUrl } from '@utils/string';
import Button from '@components/Button';
import Container from '@components/Container';
import { Body } from '@components/type';
import InterestForm from './InterestForm';
import NewForm from './NewForm';
import SaveForm from './SaveForm';
import SearchForm from './SearchForm';
import * as styled from './styles';

function HorizonPicker({ description, placeholders, title }) {
  const intl = useIntl();

  const [page, setPage] = useState(1);
  const [saveFormOpen, setSaveFormOpen] = useState(false);
  const queries = useMemo(() => [{ indexName: 'main' }], []);
  const searchRef = useRef();

  const { isLoading, search, setSearch, results: rawResults } = useAlgoliaSearch({
    initialSearch: null,
    queries,
    resolveResults: (data) =>
      get(
        data.find((d) => d.index === 'main'),
        'hits',
        [],
      ).filter(item => item.type === 'horizon'),
  });

  const results = getLocalizedData(
    rawResults,
    intl.locale,
  );

  const singleHallmark = results
    .filter((result) => result.subtype === 'hallmark')
    .slice(0, 1)
    .concat(results.filter((result) => result.subtype !== 'hallmark'));

  const paginated = singleHallmark.slice(0, page * 4);

  const handleClear = () => {
    setSearch('');

    if (searchRef.current) {
      searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleInterest = async (values) => {
    const { send: sendInterest } = emailHandler({ templateId: templateIds.HORIZONS_PICKER_ONE });
    const { send: sendStaff } = emailHandler({ templateId: templateIds.HORIZONS_INTEREST_STAFF });

    await Promise.all([
      sendInterest({
        sender: 'info@misshalls.org',
        recipient: values.email,
        variables: {
          email: values.email,
          name: values.name,
          interest: values.interest || search,
          image: get(singleHallmark, '[0].image'),
          title: get(singleHallmark, '[0].title'),
          description: get(singleHallmark, '[0].description'),
        },
      }),
      sendStaff({
        sender: 'info@misshalls.org',
        recipient: 'info@misshalls.org',
        variables: {
          name: values.name,
          email: values.email,
          interest: values.interest,
          results: singleHallmark.map((result) => result.title).join(', '),
        },
      }),
    ]);
  };

  const handleNew = async (values) => {
    const { send: sendNew } = emailHandler({ templateId: templateIds.HORIZONS_PICKER_NEW_ONE });
    const { send: sendStaff } = emailHandler({ templateId: templateIds.HORIZONS_INTEREST_STAFF });

    await Promise.all([
      sendNew({
        sender: 'info@misshalls.org',
        recipient: values.email,
        variables: {
          interest: values.interest || search,
        },
      }),
      sendStaff({
        sender: 'info@misshalls.org',
        recipient: 'info@misshalls.org',
        variables: {
          name: values.name,
          email: values.email,
          interest: values.interest,
          results: 'No results',
        },
      }),
    ]);
  };

  const handleSearch = (query) => {
    setPage(1);
    setSearch(query);
  };

  const handleSave = async (values) => {
    const { send } = emailHandler({ templateId: templateIds.HORIZONS_PICKER_EMAIL_ME });

    const cards = {};

    singleHallmark.slice(0, 3).forEach((result, idx) => {
      cards[`title${idx + 1}`] = result.title;
      cards[`description${idx + 1}`] = result.description;
      cards[`image${idx + 1}`] = result.image;
    });

    await send({
      sender: 'info@misshalls.org',
      recipient: values.email,
      variables: {
        email: values.email,
        name: values.name,
        image: get(singleHallmark, '[0].image'),
        title: get(singleHallmark, '[0].title'),
        description: get(singleHallmark, '[0].description'),
        interest: search,
        ...cards,
      },
    });
  };

  const closeSaveForm = () => setSaveFormOpen(false);
  const openSaveForm = () => setSaveFormOpen(true);

  return (
    <>
      <AnimatePresence initial={false}>
        {saveFormOpen && (
          <SaveForm onClose={closeSaveForm} onSave={handleSave} />
        )}
      </AnimatePresence>
      <Container centered columns={[12, 8]}>
        <styled.Wrapper>
          <SearchForm
            onSave={openSaveForm}
            onSearch={handleSearch}
            placeholders={placeholders}
            search={search}
            showSave={search && get(results, 'length') > 1}
            ref={searchRef}
          />
          {search && !isLoading && (
            <styled.Results>
              {paginated.map((result) => {
                const children = (
                  <styled.Result>
                    <styled.ImageWrapper
                      style={{ backgroundImage: `url(${result.image.srcWebp})` }}
                    />
                    <styled.Heading level={400}>
                      {result.title}
                    </styled.Heading>
                    <Body>{result.description}</Body>
                  </styled.Result>
                );

                if (get(result, 'slug')) {
                  if (isExternalUrl(result.slug)) {
                    return (
                      <a key={result.id} href={result.slug} target="_blank" rel="noopener noreferrer">
                        {children}
                      </a>
                    );
                  }

                  return (
                    <Link key={result.id} to={result.slug}>
                      {children}
                    </Link>
                  );
                }

                return children;
              })}
              {singleHallmark.length > paginated.length && (
                <styled.Loadmore>
                  <Button
                    appearance="green"
                    fill
                    onClick={() => setPage(page + 1)}
                  >
                    {intl.formatMessage({ id: 'loadMore' })}
                  </Button>
                </styled.Loadmore>
              )}
              {get(results, 'length') > 0 && get(results, 'length') < 2 && (
                <InterestForm interest={search} onClear={handleClear} onSubmit={handleInterest} />
              )}
              {get(results, 'length') === 0 && (
                <NewForm interest={search} onClear={handleClear} onSubmit={handleNew} />
              )}
            </styled.Results>
          )}
        </styled.Wrapper>
      </Container>
    </>
  );
}

export default HorizonPicker;
