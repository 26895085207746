import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import Image from '@components/Image';

const Wrapper = styled.div`
  position: relative;
`;

function FullWidthImage({ image, imageExtended }) {
  if (!get(image, 'asset.fluid')) {
    return null;
  }

  return (
    <Wrapper>
      <Image edge={2} fluid={image.asset.fluid} ratio={1 / 2.5} altText={imageExtended.altText}/>
    </Wrapper>
  );
}

export default FullWidthImage;
