import styled, { createGlobalStyle, css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import { AbsoluteImage } from '@components/Image';
import { Heading as UnstyledHeading } from '@components/type';

export const GlobalStyle = createGlobalStyle`
  .card--student-story.swiper-slide {
    transition: transform 500ms ease;
  }

  .block--student-stories .swiper-wrapper {
    padding: 24px 0;
  }

  ${mq.ltsm`
    .card--student-story.staggered.swiper-slide-next {
      transform: translateY(32px);
    }
  `}
`;

export const Body = styled.div`
  position: relative;
  background-color: ${(props) =>
    props.type === 'staggered'
      ? getThemeStyles(props.colorTheme).background
      : 'transparent'};

  ${mq.gtsm`
    padding: 24px ${H_PADDING_MOBILE};
  `}
`;

export const Heading = styled(UnstyledHeading)`
  position: absolute;
  z-index: 2;
  top: 0;
  margin-left: ${(3 / 12) * 100}%;
  color: ${(props) => getThemeStyles(props.colorTheme).text};

  ${mq.gtsm`
    margin-left: ${(5 / 12) * 100}%;
  `}

  ${mq.gtmd`
    margin-left: ${(7 / 12) * 100}%;
  `}
`;

export const Image = styled(AbsoluteImage)`
  transition: opacity 150ms linear;

  ${(props) => props.isHidden && css`
    opacity: 0;
    pointer-events: none;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  ${(props) => props.isPlaying && css`
    & > *:not(video):not(${Info}) {
      opacity: 0;
      pointer-events: none;
    }
  `}
`;

export const Info = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 24px 24px;
  color: ${colors.N0};
  text-align: center;
  opacity: ${(props) => props.isVisible ? 1 : 0};
  transform: translateY(${(props) => props.isVisible ? 0 : '10px'});
  transition: opacity 150ms linear, transform 200ms ease;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  pointer-events: ${(props) => props.isVisible ? 'auto' : 'none'};
`;

export const Item = styled.div`
  user-select: none;
  cursor: grab;

  ${mq.gtmd`
    flex: 1 0 calc(25% - 32px);
    margin-top: ${(props) =>
      props.type === 'staggered' ? props.index * 32 : 0}px;
    cursor: default;

    &:not(:last-child) {
      margin-right: 32px;
    }
  `}
`;

export const Items = styled.div`
  display: none;
  max-width: 1280px;
  margin: 0 auto;

  ${mq.gtmd`
    display: flex;
  `}
`;

export const MobileItems = styled.div`
  ${mq.gtmd`
    display: none;
  `}
`;

export const PlayButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  appearance: none;
  background: transparent;
  border: 0;
  height: 60px;
  width: 60px;
  margin-bottom: 8px;
  outline: 0;
  cursor: pointer;

  ${mq.gtmd`
    height: 70px;
    width: 70px;
    margin-bottom: 16px;
  `}

  & > svg:first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    fill: ${(props) => getThemeStyles(props.colorTheme).background};

    ${mq.gtsm`
      height: 70px;
      width: 70px;
    `}
  }

  & > svg:last-child {
    position: relative;
    height: 20px;
    width: 20px;
    fill: ${(props) => getThemeStyles(props.colorTheme).text};
    stroke: ${(props) => getThemeStyles(props.colorTheme).text};
    transition: transform 150ms ease;
  }

  &:hover > svg:last-child {
    transform: scale(1.25);
  }
`;

export const Question = styled(UnstyledHeading)`
  margin-bottom: 8px;
`;

export const Video = styled.video`
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  position: relative;
  padding-top: 24px;

  & > svg:first-of-type {
    display: ${(props) => (props.type === 'staggered' ? 'block' : 'none')};
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    vertical-align: bottom;
  }

  & > svg:last-of-type {
    display: ${(props) => (props.type === 'staggered' ? 'block' : 'none')};
    fill: ${(props) => getThemeStyles(props.colorTheme).background};
    transform: scale(1, -1) translateY(1px);
    vertical-align: top;
  }
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        button: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        button: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        button: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        button: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        button: colors.Y500,
        text: colors.B500,
      };
  }
}
