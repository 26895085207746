import React from 'react';
import get from 'lodash/get';

import getCTAProps from '@utils/cta';
import getFluidImage from '@utils/fluidImage';
import Chat from '@components/sidebar/Chat';
import Contacts from '@components/sidebar/Contacts';
import Downloads from '@components/sidebar/Downloads';
import EventDetails from '@components/sidebar/EventDetails';
import Events from '@components/sidebar/Events';
import Link from '@components/sidebar/Link';
import Photo from '@components/sidebar/Photo';
import Quote from '@components/sidebar/Quote';
import Person from '@components/sidebar/Person';
import Stories from '@components/sidebar/Stories';
import Block from './Block';
import * as styled from './styles';

function TextBlock({ lang, noPadding, parent, sidebar, text, anchorName }) {
  const getSidebar = () => {
    switch (sidebar.type) {
      case 'chat': {
        const props = {
          ...get(sidebar, 'chat'),
          images: get(sidebar, 'chat.images', []).map((image) => ({
            asset: {
              fluid: getFluidImage(image),
            },
          })),
        };
        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Chat {...props} />
          </styled.SidebarWrapper>
        );
      }
      case 'contacts':
        const props = {
          ...get(sidebar, 'contacts', {}),
          contacts: get(sidebar, 'contacts.contacts', []).map((contact) => ({
            ...contact,
            image: {
              asset: {
                fluid: getFluidImage(contact.imageExtended.image),
              },
            },
          })),
        };

        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Contacts {...props} />
          </styled.SidebarWrapper>
        );
      case 'downloads':
        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Downloads {...sidebar.downloads} />
          </styled.SidebarWrapper>
        );
      case 'eventDetails': {
        const eventbriteLinks = {
          en: get(sidebar, 'eventDetails.event.eventbriteLink'),
          vi: get(sidebar, 'eventDetails.event.viEventbriteLink'),
          zh_Hans: get(sidebar, 'eventDetails.event.zh_HansEventbriteLink'),
        };

        const props = {
          ...sidebar.eventDetails,
          event: {
            ...get(sidebar, 'eventDetails.event', {}),
            eventbriteLink: eventbriteLinks[lang],
          },
        };

        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <EventDetails event={parent} {...props} />
          </styled.SidebarWrapper>
        );
      }
      case 'events':
        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Events event={parent} {...sidebar.events} />
          </styled.SidebarWrapper>
        );
      case 'link': {
        const props = {
          ...get(sidebar, 'link'),
          link: {
            ...get(sidebar, 'link.link'),
            ...getCTAProps(get(sidebar, 'link.link')),
          },
          image: {
            asset: {
              fluid: getFluidImage(get(sidebar, 'link.image')),
            },
          },
        };
        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Link {...props} />
          </styled.SidebarWrapper>
        );
      }
      case 'photo': {
        const props = {
          ...get(sidebar, 'photo'),
          link: {
            ...get(sidebar, 'photo.link'),
            ...getCTAProps(get(sidebar, 'photo.link')),
          },
          image: {
            asset: {
              fluid: getFluidImage(get(sidebar, 'photo.image', {
                sizes: '(max-width: 600px) 20vw, 20vw',
              })) || getFluidImage(get(sidebar, 'photo.imageExtended.image', {
                sizes: '(max-width: 600px) 20vw, 20vw',
              })),
            },
          },
        };
        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Photo {...props} />
          </styled.SidebarWrapper>
        );
      }
      case 'quote': {
        const props = {
          ...get(sidebar, 'quote'),
        };
        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Quote {...props} />
          </styled.SidebarWrapper>
        );
      }
      case 'person': {
        const props = {
          ...get(sidebar, 'person'),
          ...{
            ...get(sidebar, 'person.person'),
            image: {
              asset: {
                fluid: getFluidImage(get(sidebar, 'person.person.imageExtended.image', {
                  sizes: '(max-width: 600px) 20vw, 20vw',
                })),
              },
            },
          },
        };
        return (
          <styled.SidebarWrapper align={sidebar.alignment}>
            <Person {...props} />
          </styled.SidebarWrapper>
        );
      }
      case 'stories': {
        if (get(sidebar, 'stories')) {
          if (sidebar.stories.type === 'select') {
            sidebar.stories.stories.forEach((item, key) => {
              sidebar.stories.stories[key].heroImage = {
                asset: {
                  fluid: getFluidImage(sidebar.stories.stories[key].heroImage, {
                    sizes: '(max-width: 600px) 20vw, 20vw',
                  }),
                },
              }
            });
          }
          return (
            <styled.SidebarWrapper align={sidebar.alignment}>
              <Stories {...sidebar.stories} />
            </styled.SidebarWrapper>
          );
        }

        return null;
      }
      default:
        return null;
    }
  };

  return (
    <styled.Wrapper noPadding={noPadding} id={anchorName}>
      {sidebar && getSidebar()}
      {Array.isArray(text) ? text.map((part, idx) => (
        <styled.TextWrapper key={idx} type={part.style}>
          {/* {getText(part)} */}
          <Block content={part} />
        </styled.TextWrapper>
      )) : ''}
    </styled.Wrapper>
  );
}

export default TextBlock;
