import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@utils/localize';

import { useLocalFormat } from '@utils/localize';
import { useTimeString } from '@utils/string';
import { Caption, Heading as UnstyledHeading } from '@components/type';
import Heading from '../Heading';
import * as styled from './styles';

function EventDetails({ event, heading }) {
  const intl = useIntl();
  const localFormat = useLocalFormat();
  const getTimeString = useTimeString();

  return (
    <styled.Wrapper>
      <Heading colorTheme="blue">{heading}</Heading>
      {event.startTime && (
        <styled.Item>
            <UnstyledHeading level={500}>
              {localFormat(new Date(event.startTime), 'MMMM do, yyyy')}
            </UnstyledHeading>
          {event.endTime && (
            <Caption size="small">
              {getTimeString(new Date(event.startTime), new Date(event.endTime))}
            </Caption>
          )}
        </styled.Item>
      )}
      {event.locationLabel && (
        <styled.Item>
          <UnstyledHeading level={500}>{event.locationLabel}</UnstyledHeading>
          {event.mapLink && (
            <Caption size="small">
              <a href={event.mapLink} target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({ id: 'getDirections' })}
              </a>
            </Caption>
          )}
        </styled.Item>
      )}
      {event.eventbriteLink && (
        <styled.Item>
          <UnstyledHeading level={500}>
            {intl.formatMessage({ id: 'eventbriteLink' })}
          </UnstyledHeading>
          <Caption size="small">
            <a
              href={event.eventbriteLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: 'getTickets' })}
            </a>
          </Caption>
        </styled.Item>
      )}
    </styled.Wrapper>
  );
}

EventDetails.propTypes = {
  event: PropTypes.object,
  heading: PropTypes.string.isRequired,
};

EventDetails.defaultProps = {
  event: {},
};

export default EventDetails;
