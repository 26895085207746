import styled, { css, createGlobalStyle } from 'styled-components';

import colors from '@utils/colors';
import { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import { CTA, Heading as UnstyledHeading } from '@components/type';

export const GlobalStyle = createGlobalStyle`
  .block--carousel .swiper-button-next,
  .block--carousel .swiper-button-prev {
    background-image: none;
    width: auto;
    height: auto;
    margin: 0;
  }

  .block--carousel .swiper-button-next {
    position: absolute;
    right: 24px;

    ${mq.gtlg`
      right: 80px;
    `}
  }

  .block--carousel .swiper-button-prev {
    position: absolute;
    left: 24px;

    ${mq.gtlg`
      left: 80px;
    `}
  }

  .block--carousel .swiper-button-disabled {
    display: none;
  }

  .block--carousel .swiper-container {
      width: 100%;
  }

  .block--carousel .swiper-wrapper {
    padding: 0 0 0 24px;
    //width: 50%;
  }

  .block--carousel .swiper-slide {
    text-align: center;
    width: auto;
  }

  .block--carousel .slide-image {
    width: auto;
  }
`;

export const Background = styled.div`
  padding: 24px;
  background-color: ${props => getThemeStyles(props.colorTheme).background};
`;

export const Credit = styled.div`
  font-size: 12px;
  font-weight: ${weights.BOLD};

  ${props => props.fixed && css`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 4px;
    background-color: rgba(255, 255, 255, 0.75);
  `}
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 8px;
`;

export const Item = styled.div`
  display: flex;
  position: relative;
  max-width: 100vh;
`;

export const Image = styled.img`
`;

export const Link = styled(CTA)`
  color: ${props => getThemeStyles(props.colorTheme).text};
`;

export const Next = styled.div`
  z-index: 1;
  display: none;

  ${mq.gtsm`
    display: block;
  `}
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  & > svg {
    margin-right: -2px;
    margin-left: -2px;
    fill: ${props => getThemeStyles(props.colorTheme).background};
    transform: translateY(2px);
  }
`;

export const Prev = styled.div`
  z-index: 1;
  display: none;

  ${mq.gtsm`
    display: block;
  `}
`;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        button: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        button: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        button: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        button: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        button: colors.Y500,
        text: colors.B500,
      };
  }
}
