import styled, { css, createGlobalStyle } from 'styled-components';

import { mq } from '@utils/responsive';
import { Heading as UnstyledHeading } from '@components/type';
import Button from '@components/Button';

import colors from '@utils/colors';
import fonts from '@utils/fonts';

export const CalendarStyles = createGlobalStyle`
  .react-datepicker__triangle {
    top: 100% !important;
    right: 0 !important;
    left: 0 !important;
    margin: 0 auto !important;
  }
`;

export const DateWrapper = styled.div`
  position: relative;
  margin-top: 4px;

  & > div {
    width: 100%;
  }

  & > svg {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
  }
`;

export const Description = styled.div`
  margin: 4px 0;
  color: ${colors.N600};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 13px;
  line-height: 18px;
`;

export const DollarSign = styled.span`
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  pointer-events: none;
`;

export const Fieldset = styled.div`
  width: 100%;

  & > * {
    width: 100%;
  }

  ${mq.gtsm`
    display: flex;
    align-items: flex-start;
    margin-top: 4px;

    & > *:not(:last-child) {
      margin-right: 32px;
    }

    ${props => props.size && css`
      & > *:nth-child(1) {
        flex: ${props.size[0]};
      }

      & > *:nth-child(2) {
        flex: ${props.size[1]};
      }
    `}
  `}
`;

export const FormWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 24px;
`;

export const InputWrapper = styled.div`
  position: relative;

  & input {
    padding-left: 24px;
  }

  ${props => props.locked && css`
    & input {
      pointer-events: none;
      background-color: ${colors.B100};
    }
  `}
`;

export const LabeledSection = styled.div`
  & > *:first-child {
    margin-bottom: 8px;
  }

  ${mq.gtmd`
    display: flex;

    & > *:first-child {
      width: 120px;
      margin-right: 24px;
      margin-bottom: 0;
    }

    & > *:last-child {
      flex: 1 0 auto;
    }
  `}
`;

export const Radio = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  margin-top: 4px;
  padding: 0 8px;
  appearance: none;
  font-size: 16px;
  border: 2px solid ${(props) => (props.checked ? colors.B500 : colors.B100)};
  border-radius: 8px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${mq.gtsm`
    height: 48px;
  `}
`;

export const Section = styled.div`
  margin-bottom: 24px;
`;

export const Select = styled.select`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 8px;
  appearance: none;
  font-size: 16px;
  border: 2px solid ${colors.B100};
  border-radius: 8px;
  cursor: pointer;

  ${mq.gtsm`
    height: 48px;
  `}
`;

export const SelectWrapper = styled.div`
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    height: 16px;
    width: 16px;
    margin: auto 0;
    fill: ${colors.B500};
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  text-align: center;

  & > button {
    margin-top: 32px;
  }
`;

export const Submit = styled.div`
  padding-top: 24px;
  text-align: center;
`;

export const Wrapper = styled.div`
  font-family: ${props => fonts(props.theme.locale).SANS};
`;

export const ItemsContainer = styled.div``;
export const ItemWrapper = styled.div`
  display: flex;
  @media (max-width: 600px) {
    padding: 5px 0;
  }
`;
export const ItemQuantityCol = styled.div`
  flex: 10%;
  min-width: 50px;
  padding: 0 10px 0 0;
`;
export const ItemQuantityHeading = styled.div`
  font-size: 16px;
  font-family: ${props => fonts(props.theme.locale).SANS_CONDENSED};
  text-align: center;
`
export const ItemPriceCol = styled.div`
  flex: 15%;
  min-width: 80px;
  padding: 3px 10px 0 0;
  font-weight: bold;
  font-size: 22px;
  font-family: ${props => fonts(props.theme.locale).SANS};
  text-align: right;
  color: ${props => props.isDisabled ? '#7d7c7c': 'inherit'};
  text-decoration: ${props => props.isDisabled ? 'line-through' : 'inherit'};
`;
export const ItemHeadingCol = styled.div`
  flex: 75%;
  padding: 3px 0 0 30px;
  font-size: 18px;
  font-family: ${props => fonts(props.theme.locale).SANS};
  color: ${props => props.isDisabled ? '#7d7c7c': 'inherit'};
  text-decoration: ${props => props.isDisabled ? 'line-through' : 'inherit'};
`;
export const ItemSubHeading = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-style: italic;
  font-family: ${props => fonts(props.theme.locale).SANS};
  color: ${props => props.isDisabled ? '#7d7c7c': 'inherit'};
  text-decoration: ${props => props.isDisabled ? 'line-through' : 'inherit'};
`;
export const ItemsTotalContainer = styled.div`
  margin: 20px auto 0;
  width: 50%;
`;
export const CompleteDonationButton = styled(Button)`
  margin: 0 0 0 20px;
`;
export const CompleteDonationButtonWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
`;
export const SuccessBlockParagraph = styled.div`
  padding: 0 0 10px 0;
`;
export const CaptchaContainer = styled.div`
  text-align: center;
  margin: 10px auto 20px;
`
