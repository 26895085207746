import React from 'react';
import styled from 'styled-components';

import colors from '@utils/colors';
import { Heading as UnstyledHeading } from '@components/type';

const Wrapper = styled.div`
  padding-bottom: 16px;
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme)};
`;

function SidebarHeading({ children, colorTheme }) {
  return (
    <Wrapper colorTheme={colorTheme}>
      <UnstyledHeading levels={[500, 400]}>{children}</UnstyledHeading>
    </Wrapper>
  );
}

export default SidebarHeading;

function getThemeStyles(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.Y100;
    case 'green':
      return colors.G100;
    case 'orange':
      return colors.O100;
    case 'purple':
      return colors.P100;
    default:
      return colors.B100;
  }
}
