import styled from 'styled-components';
import { motion } from 'framer-motion';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { Heading as UnstyledHeading } from '@components/type';

export const Fieldset = styled.div`
  width: 100%;

  & > * {
    width: 100%;
  }

  ${mq.gtsm`
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 32px;
    }
  `}
`;

export const Form = styled.form`
  margin-top: 24px;

  & > button {
    margin-top: 8px;
  }

  ${mq.gtsm`
    margin-top: 32px;
  `}
`;

export const Heading = styled(UnstyledHeading)`
  margin-bottom: 4px;

  ${mq.gtsm`
    margin-bottom: 8px;
  `}
`;

export const Inner = styled.div`
  position: relative;
  flex: 1 0 auto;
  padding: 32px 16px;
  background-color: ${colors.G100};
  overflow: hidden;
`;

export const Success = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  padding: 48px;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;

  & > svg {
    transform: translateY(-2px) scale(1, -1);
  }
`;
