import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import fonts from '@utils/fonts';
import { mq } from '@utils/responsive';
import Image from '@components/Image';
import { Body, Heading } from '@components/type';
import { RippedOne } from '@components/vector';
import getFluidImage from '@utils/fluidImage';

const GlobalStyle = createGlobalStyle`
  .card--quote.swiper-slide {
    opacity: 1;
    transform: rotate(-1deg);
    transition: opacity 250ms linear, transform 500ms ease;
  }

  .card--quote.swiper-slide-next,
  .card--quote.swiper-slide-prev {
    transform: rotate(1deg);
  }

  ${mq.gtsm`
    .card--quote.swiper-slide-active,
    .card--quote.swiper-slide-prev {
      opacity: 0.1;
    }
  `}
`;

const Edge = styled(RippedOne)`
  position: absolute;
  right: -2px;
  bottom: -1px;
  left: -2px;
  width: calc(100% + 4px);
  fill: ${colors.N0};
`;

const ImageWrapper = styled.div`
  position: relative;
  flex: 0 1 112px;
  margin-right: 16px;

  ${mq.gtsm`
    margin-right: 24px;
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Quote = styled.p`
  margin-bottom: 24px;
  color: ${props => getQuoteColor(props.colorTheme)};
  font-family: ${props => fonts(props.theme.locale).HANDWRITTEN};
  font-size: 18px;
  line-height: 130%;

  ${mq.gtsm`
    margin-bottom: 64px;
    font-size: 30px;
    line-height: 140%;
  `}
`;

const Student = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  padding: 16px;
  background-color: ${colors.N0};

  ${mq.gtsm`
    padding: 24px;
  `}
`;

function QuoteCard({ imageExtended, name, quote, colorTheme, title }) {
  const image = {
    ...imageExtended.image.asset,
    fluid: getFluidImage(imageExtended.image, { sizes: '(max-width: 600px) 100vw, 50vw'})
  };
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Quote colorTheme={colorTheme}>&ldquo;{quote}&rdquo;</Quote>
        <Student>
          {get(image, 'fluid') && (
            <ImageWrapper>
              <Image fluid={image.fluid} draggable={false} altText={imageExtended.altText}/>
              <Edge />
            </ImageWrapper>
          )}
          <Info>
            <Heading levels={[500, 300]}>{name}</Heading>
            {title && <Body sizes={['small', 'regular']}>{title}</Body>}
          </Info>
        </Student>
      </Wrapper>
    </>
  );
}

export default QuoteCard;

function getQuoteColor(colorTheme) {
  switch (colorTheme) {
    case 'blue':
      return colors.B500;
    case 'green':
      return colors.G500;
    case 'orange':
      return colors.O500;
    case 'purple':
      return colors.P500;
    default:
      return colors.B500;
  }
}
