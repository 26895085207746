import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'swiper/js/swiper.esm';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { breakpoints } from '@utils/responsive';
import NavButton from '@components/NavButton';
import Swiper from '@components/Swiper';
import { RippedTwo } from '@components/vector';
import * as styled from './styles';

function Carousel({ items, colorTheme }) {
  const [swiper, updateSwiper] = useState(null);
  const [dimensions, setDimensions] = React.useState({
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
  });

  const params = {
    modules: [Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderNextButton: () => (
      <styled.Next className="swiper-button-next" colorTheme={colorTheme}>
        <NavButton
          direction="next"
          sizes={['regular', 'large']}
          colorTheme={colorTheme}
        />
      </styled.Next>
    ),
    renderPrevButton: () => (
      <styled.Prev className="swiper-button-prev" colorTheme={colorTheme}>
        <NavButton
          direction="prev"
          sizes={['regular', 'large']}
          colorTheme={colorTheme}
        />
      </styled.Prev>
    ),
    slidesOffsetAfter: 40,
    slidesPerView: 'auto',
    spaceBetween: 16,
    breakpoints: {
      [breakpoints.mobile]: {
        slidesPerView: 'auto',
        slidesOffsetAfter: 40,
        spaceBetween: 16,
      },
    },
    updateOnWindowResize: true,
    getSwiper: updateSwiper,
  };

  useEffect(() => {
    function handleResize() {
      if (swiper !== null) {
        swiper.update();
      }

      setDimensions({
        height: typeof window !== 'undefined' ? window.innerHeight : 0,
        width: typeof window !== 'undefined' ? window.innerWidth : 0,
      });
    }

    const debouncedResize = debounce(handleResize, 200);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', debouncedResize);
    }

    return function cleanup() {
      debouncedResize.cancel();

      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', debouncedResize);
      }
    };
  });

  return (
    <div className="block--carousel">
      <styled.GlobalStyle />
      <Swiper params={params}>
        {items.map((item) => (
          <styled.Item key={item._key} class="swiper-slide">
            <styled.Image
              src={get(item, 'imageExtended.image.asset.url') + '?h=300'}
              class="slide-image"
              alt={item.imageExtended.altText}
              height="300"
            />
            {get(item, 'imageExtended.credit') && !item.heading &&
              <styled.Credit fixed>{item.imageExtended.credit}</styled.Credit>
            }
            {item.heading && (
              <styled.Overlay colorTheme={colorTheme}>
                <RippedTwo />
                <styled.Background colorTheme={colorTheme}>
                  <styled.Heading levels={[400, 200]}>
                    {item.heading}
                  </styled.Heading>
                  {get(item, 'cta.route.slug.current') ? (
                    <styled.Link
                      to={item.cta.route.slug.current}
                      sizes={['small', 'regular']}
                      colorTheme={colorTheme}
                    >
                      {item.cta.label}
                    </styled.Link>
                  ) : (
                    get(item, 'cta.link') && (
                      <styled.Link
                        external
                        href={item.cta.link}
                        sizes={['small', 'regular']}
                        colorTheme={colorTheme}
                      >
                        {item.cta.label}
                      </styled.Link>
                    )
                  )}
                  {get(item, 'imageExtended.credit') &&
                    <styled.Credit>({item.imageExtended.credit})</styled.Credit>
                  }
                </styled.Background>
              </styled.Overlay>
            )}
          </styled.Item>
        ))}
      </Swiper>
    </div>
  );
}

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

Carousel.defaultProps = {
  colorTheme: 'yellow',
};

export default Carousel;
