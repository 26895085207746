import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useIntl } from '@utils/localize';
import get from 'lodash/get';

import colors from '@utils/colors';
import Button from '@components/Button';
import { Close } from '@components/Icon';
import { Field, FormError, Input, Label } from '@components/Contact/styles';
import { Body } from '@components/type';
import { RoughOne } from '@components/vector';
import * as styled from './styles';

function SaveForm({ onClose, onSave }) {
  const intl = useIntl();

  const { errors, formState, handleSubmit, register } = useForm({
    mode: 'onChange',
  });
  const [submitted, setSubmitted] = useState(false);

  const _handleSubmit = async (values) => {
    await onSave(values);
    setSubmitted(true);
  };

  return (
    <styled.Wrapper
      initial="enter"
      animate="center"
      exit="exit"
      variants={{
        enter: { opacity: 0 },
        center: { opacity: 1 },
        exit: { opacity: 0 },
      }}
      onClick={onClose}
    >
      <styled.Modal
        initial="enter"
        exit="exit"
        variants={{
          enter: { opacity: 0, y: 60 },
          center: { opacity: 1, y: 0 },
          exit: { opacity: 0, y: 60 },
        }}
        onClick={(evt) => evt.stopPropagation()}
      >
        <styled.CloseButton onClick={onClose}>
          <Close />
        </styled.CloseButton>
        <styled.Inner>
          <styled.Success
            animate={submitted ? 'center' : 'enter'}
            initial="enter"
            variants={{
              enter: {
                opacity: 0,
                x: '100%',
              },
              center: {
                opacity: 1,
                x: '0%',
              },
            }}
          >
            <styled.Heading level={300}>
              {intl.formatMessage({
                id: 'horizonPicker.saveForm.success.heading',
              })}
            </styled.Heading>
            <Body>
              {intl.formatMessage({
                id: 'horizonPicker.saveForm.success.body',
              })}
            </Body>
            <Button appearance="green" isFullWidth onClick={onClose}>
              {intl.formatMessage({
                id: 'horizonPicker.saveForm.success.button',
              })}
            </Button>
          </styled.Success>
          <motion.div
            animate={submitted ? 'exit' : 'center'}
            initial="center"
            variants={{
              center: {
                opacity: 1,
                x: '0%',
              },
              exit: {
                opacity: 0,
                x: '-100%',
              },
            }}
          >
            <styled.Heading level={300}>
              {intl.formatMessage({ id: 'horizonPicker.saveForm.heading' })}
            </styled.Heading>
            <Body>
              {intl.formatMessage({ id: 'horizonPicker.saveForm.description' })}
            </Body>
            <styled.Form onSubmit={handleSubmit(_handleSubmit)}>
              <Field>
                <Label>
                  {intl.formatMessage({
                    id: 'horizonPicker.saveForm.label.name',
                  })}
                </Label>
                <Input color={colors.G200} name="name" ref={register} />
              </Field>
              <Field>
                <Label error={get(formState, 'touched.email') && errors.email}>
                  {intl.formatMessage({
                    id: 'horizonPicker.saveForm.label.email',
                  })}
                  *
                </Label>
                <Input
                  error={get(formState, 'touched.email') && errors.email}
                  name="email"
                  type="email"
                  ref={register({
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                  color={colors.G200}
                />
                {get(formState, 'touched.email') && errors.email && (
                  <FormError>
                    {intl.formatMessage({
                      id: 'horizonPicker.saveForm.error.email',
                    })}
                  </FormError>
                )}
              </Field>
              <Button
                appearance="green"
                isDisabled={!get(formState, 'isValid')}
                isFullWidth
                type="submit"
              >
                {intl.formatMessage({ id: 'horizonPicker.saveForm.submit' })}
              </Button>
            </styled.Form>
          </motion.div>
        </styled.Inner>
        <RoughOne color={colors.G100} />
      </styled.Modal>
    </styled.Wrapper>
  );
}

export default SaveForm;
