import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';

import { useLocalFormat } from '@utils/localize';
import Container from '@components/Container';
import { Pause, Play } from '@components/Icon';
import { Heading } from '@components/type';
import { BlobTwo } from '@components/vector';
import * as styled from './styles';

const getTime = (seconds) => {
  const time = !isFinite(seconds) ? 0 : seconds;
  return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
};

function AudioBlock({ date, file, colorTheme, title }) {
  const player = useRef(null);
  const bar = useRef(null);
  const localFormat = useLocalFormat();

  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const play = () => {
    player.current.play();
    setPlaying(true);
    setPaused(false);
  };

  const pause = () => {
    player.current.pause();
    setPaused(true);
  };

  const reset = () => {
    player.current.pause();
    player.current.currentTime = 0;
  };

  const setMetadata = () => {
    setDuration(player.current.duration);
  };

  const getTimePixelOffset = (evt) => {
    if (!bar.current) {
      return;
    }

    const clickPositionInPage = evt.pageX;
    const barStart = bar.current.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.current.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  };

  const handleTimeDrag = (evt) => {
    if (!player.current) {
      return;
    }

    const playerIsMuted = player.current.muted;

    player.current.currentTime = getTimePixelOffset(evt);

    const updateTimeOnMove = (moveEvt) => {
      if (!playerIsMuted) {
        player.current.muted = true;
      }

      player.current.currentTime = getTimePixelOffset(moveEvt);
    };

    document.addEventListener('pointermove', updateTimeOnMove);

    document.addEventListener('pointerup', () => {
      document.removeEventListener('pointermove', updateTimeOnMove);

      if (!playerIsMuted) {
        player.current.muted = false;
      }
    });
  };

  useEffect(() => {
    const playerNode = player.current;

    const updateProgress = () => {
      setProgress(playerNode.currentTime / playerNode.duration);
    };

    if (get(player, 'current')) {
      playerNode.addEventListener('loadedmetadata', setMetadata);
      playerNode.addEventListener('timeupdate', updateProgress);
      playerNode.addEventListener('ended', reset);
    }

    return function cleanup() {
      playerNode.removeEventListener('timeupdate', updateProgress);
      playerNode.removeEventListener('ended', reset);
    };
  });

  return (
    <Container centered columns={[12, 8]}>
      <styled.Wrapper colorTheme={colorTheme}>
        <styled.FileDetails>
          <styled.Uploaded sizes={['small', 'regular']}>
            {localFormat(new Date(date), 'MMMM d, yyyy')}
          </styled.Uploaded>
          <Heading levels={[400, 300]}>{title}</Heading>
        </styled.FileDetails>
        <styled.Player>
          <audio preload="metadata" ref={player}>
            <source src={file.asset.url} />
          </audio>
          <styled.PlayButton
            onClick={paused || !playing ? play : pause}
            colorTheme={colorTheme}
            aria-label="Play"
          >
            <BlobTwo />
            {paused || !playing ? <Play /> : <Pause />}
          </styled.PlayButton>
          <styled.Time size="small">
            {getTime(get(player, 'current.currentTime', 0))}/
            {getTime(get(player, 'current.duration', 0))}
          </styled.Time>
          <styled.ProgressWrapper onPointerDown={handleTimeDrag}>
            <styled.Progress ref={bar} colorTheme={colorTheme}>
              <styled.ProgressBar
                style={{ width: `${progress * 100}%` }}
                colorTheme={colorTheme}
              />
            </styled.Progress>
            <styled.CurrentTime
              style={{ left: `calc(${progress * 100}% - 12px)` }}
              colorTheme={colorTheme}
            />
          </styled.ProgressWrapper>
        </styled.Player>
      </styled.Wrapper>
    </Container>
  );
}

export default AudioBlock;
