import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGoal } from 'gatsby-plugin-fathom'
import numeral from 'numeral';

import { useIntl } from '@utils/localize';
import Button from '@components/Button';
import Checkbox from '@components/Checkbox';
import { Field, Label } from '@components/Contact/styles';
import Container from '@components/Container';
import { ChevronDown } from '@components/Icon';
import calculationFormula from './formula';
import * as styled from './styles';
import Image from '../Image';
import { Body } from '@components/type';

function TuitionCalculator({ limits, fathomGoalId }) {
  const intl = useIntl();
  const resultRef = useRef(null);
  const { handleSubmit, register } = useForm({
    mode: 'onChange',
  });
  const [studentType, setStudentType] = useState('boarding');
  const [result, setResult] = useState(null);

  const onSubmit = (values) => {
    const { assets, familySize, income, students } = {
      assets: values.assets === 'top' ? 625000 : values.assets,
      familySize: values.familySize === 'top' ? 8 : values.familySize,
      income: values.income === 'top' ? 312500 : values.income,
      students: values.students === 'top' ? 7 : values.students,
    };

    const boarding = studentType === 'boarding' ? 1 : 0;
    const newResult = calculationFormula(income, familySize, assets, boarding, students);

    const getEstimate = (values, limits, calculatedResult) => {
      const estimate = {
        inaccurate: Boolean(
          Object.values(values).find((value) => value === 'top'),
        ),
      };

      if (calculatedResult < limits[studentType].lowerBound) {
        estimate.lowerBound = limits[studentType].lowerBound;
        estimate.lowerDisplay = limits[studentType].lowerDisplay;
        estimate.monthlyLowerDisplay = limits[studentType].monthlyLowerDisplay;
      } else if (calculatedResult > limits[studentType].upperBound) {
        estimate.upperBound = limits[studentType].upperBound;
        estimate.upperDisplay = limits[studentType].upperDisplay;
        estimate.monthlyUpperDisplay = limits[studentType].monthlyUpperDisplay;
      } else {
        estimate.lowerBound = Math.round(calculatedResult / 1000) * 1000;
        estimate.lowerDisplay = numeral(estimate.lowerBound).format('$0,0');
        estimate.upperBound = Math.round(calculatedResult / 1000) * 1000 + 5000;
        estimate.upperDisplay = numeral(estimate.upperBound).format('$0,0');
        estimate.monthlyLowerDisplay = numeral(estimate.lowerBound*0.09).format('$0,0');
        estimate.monthlyUpperDisplay = numeral(estimate.upperBound*0.09).format('$0,0');
      }

      return estimate;
    };

    const estimate = getEstimate(values, limits, newResult);
    fathomGoalId && useGoal(fathomGoalId, true)();

    setResult(estimate);

    if (resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const contactPerson = (
    <styled.Contact sizes={['small', 'regular']}>
      <strong>David Hopkins</strong>
    </styled.Contact>
  );

  return (
    <Container centered columns={[12, 6]}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field>
          <Label>
            {intl.formatMessage({
              id: 'tuitionCalculator.form.label.studentType',
            })}
            *
          </Label>
          <styled.Radio
            checked={studentType === 'boarding'}
            onClick={() => {
              setStudentType('boarding');
            }}
          >
            <Checkbox
              checked={studentType === 'boarding'}
              label={intl.formatMessage({
                id: 'tuitionCalculator.form.field.studentType.boardingStudent',
              })}
              onChange={() => {
                setStudentType('boarding');
              }}
              round
            />
          </styled.Radio>
          <styled.Radio
            checked={studentType === 'day'}
            onClick={() => {
              setStudentType('day');
            }}
          >
            <Checkbox
              checked={studentType === 'day'}
              label={intl.formatMessage({
                id: 'tuitionCalculator.form.field.studentType.dayStudent',
              })}
              onChange={() => {
                setStudentType('day');
              }}
              round
            />
          </styled.Radio>
        </Field>
        <styled.Fieldset>
          <Field>
            <Label>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.label.householdIncome',
              })}
              *
              <styled.SelectWrapper>
                <styled.Select name="income" ref={register({ required: true })}>
                  <option value="12500">$0 - $24,999</option>
                  <option value="37500">$25,000 - $49,999</option>
                  <option value="62500">$50,000 - $74,999</option>
                  <option value="87500">$75,000 - $99,999</option>
                  <option value="112500">$100,000 - $124,999</option>
                  <option value="137500">$125,000 - $149,999</option>
                  <option value="162500">$150,000 - $174,999</option>
                  <option value="187500">$175,000 - $199,999</option>
                  <option value="212500">$200,000 - $224,999</option>
                  <option value="237500">$225,000 - $249,999</option>
                  <option value="262500">$250,000 - $274,999</option>
                  <option value="287500">$275,000 - $299,999</option>
                  <option value="top">$300,000+</option>
                </styled.Select>
                <ChevronDown />
              </styled.SelectWrapper>
            </Label>
            <styled.Description>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.description.householdIncome',
              })}
            </styled.Description>
          </Field>
          <Field>
            <Label>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.label.assets',
              })}
              *
              <styled.SelectWrapper>
                <styled.Select name="assets" ref={register({ required: true })}>
                  <option value="25000">$0 - $49,999</option>
                  <option value="75000">$50,000 - $99,999</option>
                  <option value="125000">$100,000 - $149,999</option>
                  <option value="175000">$150,000 - $199,999</option>
                  <option value="225000">$200,000 - $249,999</option>
                  <option value="275000">$250,000 - $299,999</option>
                  <option value="325000">$300,000 - $349,999</option>
                  <option value="375000">$350,000 - $399,999</option>
                  <option value="425000">$400,000 - $449,999</option>
                  <option value="475000">$450,000 - $499,999</option>
                  <option value="525000">$500,000 - $549,999</option>
                  <option value="575000">$550,000 - $599,999</option>
                  <option value="top">$600,000+</option>
                </styled.Select>
                <ChevronDown />
              </styled.SelectWrapper>
            </Label>
            <styled.Description>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.description.assets',
              })}
            </styled.Description>
          </Field>
        </styled.Fieldset>
        <styled.Fieldset>
          <Field>
            <Label>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.label.familySize',
              })}
              *
              <styled.SelectWrapper>
                <styled.Select
                  name="familySize"
                  ref={register({ required: true })}
                >
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="top">8+</option>
                </styled.Select>
                <ChevronDown />
              </styled.SelectWrapper>
            </Label>
            <styled.Description>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.description.familySize',
              })}
            </styled.Description>
          </Field>
          <Field>
            <Label>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.label.enrolledStudents',
              })}
              *
              <styled.SelectWrapper>
                <styled.Select
                  name="students"
                  ref={register({ required: true })}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="top">7+</option>
                </styled.Select>
                <ChevronDown />
              </styled.SelectWrapper>
            </Label>
            <styled.Description>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.description.enrolledStudents',
              })}
            </styled.Description>
          </Field>
        </styled.Fieldset>
        <styled.ButtonWrapper>
          <Button appearance="blue" fill type="submit">
            {intl.formatMessage({ id: 'tuitionCalculator.form.submit' })}
          </Button>
        </styled.ButtonWrapper>
      </form>
      {result && (
        <>
          <styled.Divider />
          <styled.Result ref={resultRef}>
            <styled.Caption sizes={['small', 'regular']}>
              {intl.formatMessage({
                id: 'tuitionCalculator.form.result.estimate',
              })}
            </styled.Caption>
            <styled.EstimateLabel>Estimated tuition range</styled.EstimateLabel>
            <styled.Estimate level={100}>
              {result.lowerDisplay}
              {result.lowerDisplay && result.upperDisplay && ' – '}
              {result.upperDisplay}
            </styled.Estimate>
            <styled.MonthlyEstimateContainer>
              <styled.MonthlyEstimateIntro>
                Or as low as
              </styled.MonthlyEstimateIntro>
              <styled.MonthlyEstimateRange level={200}>
                {result.monthlyLowerDisplay}
                {result.monthlyLowerDisplay && result.monthlyUpperDisplay && ' – '}
                {result.monthlyUpperDisplay}
              </styled.MonthlyEstimateRange>

              <styled.MonthlyEstimateLabel>per month</styled.MonthlyEstimateLabel>
              <styled.MonthlyEstimateLabelSecond>with payment plan</styled.MonthlyEstimateLabelSecond>
            </styled.MonthlyEstimateContainer>
            <Container centered columns={[12, 10]}>
              <styled.ContactDetailsContainer>
                <styled.ContactDetailsImageWrapper>
                  <Image
                    fluid={{
                      src: 'https://cdn.sanity.io/images/yln0jo77/production/b2cab29c17f4a6cc6cda69206e6d4ce5883bd497-834x834.jpg?w=300&h=300&fit=crop',
                      srcSet: 'https://cdn.sanity.io/images/yln0jo77/production/b2cab29c17f4a6cc6cda69206e6d4ce5883bd497-834x834.jpg?w=300&h=300&fit=crop 2550w',
                      sizes: '(max-width: 2550px) 100vw, 2550px',
                      aspectRatio: 0.8,
                    }}
                    edge={0}
                  />
                </styled.ContactDetailsImageWrapper>
                <styled.ContactDetailsContent>
                  <Body>
                    <styled.ContactDetailsHeader>Yes, you can afford Miss Hall’s! Here is your next step:</styled.ContactDetailsHeader>
                    <div>Please complete the <a href="https://app.clarityapp.com/sign-up" title="Clarity financial survey" target="_blank">Clarity financial survey</a>.
                      This takes 30 minutes or less. Your student will never feel as connected
                      to school as they will at Miss Hall’s. Email David Hopkins, our director of admissions, with any questions and
                      find out how you can join our community today!</div>
                    <br />
                    <Button href="mailto:dhopkins@misshalls.org" fill>Email David</Button>
                  </Body>
                </styled.ContactDetailsContent>
              </styled.ContactDetailsContainer>
            </Container>
            <Container centered columns={[12, 5]}>
              <styled.Disclaimer size="small">
                {result.inaccurate && (
                  <>
                    {intl.formatMessage({
                      id: 'tuitionCalculator.form.result.inaccurate.beginning',
                    })}{' '}
                    {contactPerson}
                    {' '}
                    {intl.formatMessage({
                      id: 'tuitionCalculator.form.result.inaccurate.end',
                    })}
                  </>
                )}
              </styled.Disclaimer>
            </Container>
          </styled.Result>
        </>
      )}
    </Container>
  );
}

export default TuitionCalculator;
